import { buildNumberRange } from "@/utils/array";

/**
 * In AWS, 1024 CPU units is equal to 1 vCPU. Since we have to provide CPU units
 * to APIs, we keep things internally in CPU units, but convert for display.
 * eg. The user selects "4 vCPUs", and internally we use 4096 CPU units.
 */
export const CPU_UNIT_TO_VCPU = 1024;

/**
 * All possible CPU unit to RAM match-ups which both we and AWS task definitions
 * support. To view these limits, go to AWS ECS / Task definitions. Select any
 * option, select any revision, and choose "Create new revision". Scrolling down
 * to "Environment", view the "Task size" settings.
 */
export const ecsTaskCpuUnitsRamMatches: Record<number, number[]> = {
  256: [512, 1024, 2048],
  512: buildNumberRange(1024, 4096, 1024),
  1024: buildNumberRange(2048, 8192, 1024),
  2048: buildNumberRange(4096, 16384, 1024),
  4096: buildNumberRange(8192, 30720, 1024),
  8192: buildNumberRange(16384, 61440, 4096),
  16384: buildNumberRange(32768, 122880, 8192),
};
