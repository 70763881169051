/**
 * Builds a list of numbers within an inclusive range, with an optional step.
 * @param start - Number to begin at.
 * @param end - Number to not go past (may not be included if step creates remainder).
 * @param step - Number to increment with each loop (Default: 1).
 */
export const buildNumberRange = (start: number, end: number, step = 1): number[] => {
  let pointer = start;
  let list: number[] = [];

  do {
    list = [...list, pointer];
    pointer += step;
  } while (pointer <= end);

  return list;
};
