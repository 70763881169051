import { RiskBandsAdvancedSettingsRefs } from "@/components/layouts/RiskBandsAdvancedSettings";
import { getKbConfig, getRiskBandsMode } from "@/features/branding";
import { InteractiveTutorial, InteractiveTutorialProps } from "@/features/interactive_tutorial";
import { SingleResultModalRefs } from "@/tools/risk-bands/components/SingleResultModal";
import { Alert, TourStepProps } from "antd";
import { LatLngLiteral } from "leaflet";
import Link from "next/link";
import { MutableRefObject, useEffect } from "react";
export interface SingleAssetScreenState {
  address: string;
  position: LatLngLiteral;
}
const tutorialState: SingleAssetScreenState = {
  address: "Disneyland Hong Kong, Lantau Island, Hong Kong",
  position: {
    lat: 22.3129666,
    lng: 114.0412819
  }
};
export interface SingleAssetScreenTutorial extends InteractiveTutorialProps<SingleAssetScreenState> {
  refs: {
    address: MutableRefObject<null>;
    check: MutableRefObject<null>;
    map: MutableRefObject<null>;
    advancedSettings?: RiskBandsAdvancedSettingsRefs;
    modal?: SingleResultModalRefs;
  };
}
export const SingleAssetScreenTutorial = ({
  refs,
  ...props
}: SingleAssetScreenTutorial) => {
  useEffect(() => {
    if (props.open) {
      props.setState(tutorialState);
    }
  }, [props.open]);
  const steps: TourStepProps[] = [{
    title: "To enter an asset by address",
    description: <>
          <p>
            Under &apos;<b>Search For A Location</b>&apos;, click the search bar and enter an asset address. Select
            address from drop-down prompts if available.
          </p>
          <p>
            Please enter addresses in the following format where possible:
            <br />
            <i>Street number, Street Name, Village/Town/District, Region</i>
          </p>
          <Alert description="Do not enter unit numbers, suite numbers or floor numbers." showIcon type="error" />
        </>,
    target: () => refs.address.current
  }, {
    title: "Under 'Select A Point', click a point on the map to drop the location pin to a precise location if the address result is not accurate.",
    description: <>
          <p>
            To enter an asset location by selecting a point on the map, single-click on an asset location in the map
            window to drop a location pin. This will generate a Latitude and Longitude value in the &apos;
            <b>Search For A Location</b>&apos; field.
          </p>
          <p>
            Click and drag anywhere in the map window to navigate. Click &apos;+&apos; button to zoom in, &apos;-&apos;
            to zoom out.
          </p>
        </>,
    target: () => refs.map.current
  }, {
    title: "Under 'Settings', choose an asset type from the 'Asset Type (Archetype)' selection drop-down menu.",
    description: getKbConfig().glossary.enabled && <>
          <p>
            For more information on selecting archetypes, see{" "}
            <Link href="/glossary/archetype" target="_blank">
              Glossary – Archetype
            </Link>
            .
          </p>
        </>,
    target: () => refs.advancedSettings!.archetype.current
  }, {
    title: "Enter asset build year.",
    description: <>
          <p>
            If unsure of the year the asset was built, leave default <b>Build Year</b> value in the field, or enter
            exact/estimated <b>Build Year</b> if known.
          </p>
        </>,
    target: () => refs.advancedSettings!.buildYear.current
  }, {
    title: "Enter the asset's 'Height Above Ground'.",
    description: <>
          <p>
            You can estimate this by selecting a foundation type from the drop-down menu, or choose &apos;<b>Custom</b>
            &apos; to enter a precise height value (metres).
          </p>
        </>,
    target: () => refs.advancedSettings!.heightAboveGround.current
  }, {
    title: "",
    description: <>
          Click &apos;<b>Check</b>&apos; to begin the asset screen and view the asset&apos;s Risk Rating.
        </>,
    target: () => refs.check.current
  }];
  if (getRiskBandsMode() !== "abc") {
    steps.push({
      title: "",
      description: "Use the drop-down menu on the bottom right to compare between four different RCP scenarios – 2.6, 4.5, 6.0 or 8.5.",
      target: () => refs.modal?.rcp.current || null
    });
  }
  steps.push(...[{
    title: "",
    description: <>
            Click &apos;<b>Download .csv</b>&apos; to download data as a .csv file.
          </>,
    target: () => refs.modal?.downloadCsv.current || null
  }, {
    title: "",
    description: <>
            To exit results and screen a different address, click &apos;<b>Back</b>&apos;.
          </>,
    target: () => refs.modal?.back.current || null
  }]);
  return <InteractiveTutorial<SingleAssetScreenState> steps={steps} {...props} data-sentry-element="InteractiveTutorial" data-sentry-component="SingleAssetScreenTutorial" data-sentry-source-file="single.tsx" />;
};