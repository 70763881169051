import styles from "@/components/overlays/Loading.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import classNames from "classnames";
export interface LoadingProps {
  isOpaque?: boolean;
}
export const Loading = ({
  isOpaque
}: LoadingProps) => {
  const classes = classNames(styles.container, {
    [styles["container--opaque"]]: isOpaque
  });
  return <div className={classes} data-sentry-component="Loading" data-sentry-source-file="Loading.tsx">
      <LoadingOutlined className={styles.icon} data-sentry-element="LoadingOutlined" data-sentry-source-file="Loading.tsx" />
    </div>;
};