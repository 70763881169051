import { PortfolioExportRawSchema } from "@/tools/aggregate/portfolio-export/schema";
import { parse } from "csv-parse/sync";

/**
 *
 * @param line -
 * @param header -
 * @returns
 */
export const parsePortfolioExportUploadLine = (line: string, header: string): PortfolioExportRawSchema => {
  const data = parse([header, line].join("\n"), {
    cast: castFn, // default cast returned empty strings for null fields
    columns: true,
    trim: true,
    relax_column_count: true,
  });
  return data[0];
};

const isFloat = (val: any) => val - parseFloat(val) + 1 >= 0;
const castFn = (val: any) => {
  if (val === "") return null;
  return isFloat(val) ? parseFloat(val) : val;
};
