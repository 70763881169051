import { Dashboard } from "@/types/dashboards";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 } from "uuid";

export interface DashboardsState {
  dashboards: Dashboard[];
}

const initialState: DashboardsState = {
  dashboards: [
    {
      id: "1",
      type: "full",
      name: "Single Asset Dashboard",
      modules: [
        {
          id: v4(),
          type: "chart",
          mode: "bar2d",
          width: "double",
          template: {
            type: "totalRiskCost",
            overrides: {
              singleAnaAsset: {
                cartesian: {
                  xAxis: {
                    step: 10,
                  },
                },
              },
            },
          },
        },
        {
          id: v4(),
          type: "chart",
          mode: "bar2d",
          width: "single",
          template: {
            type: "hazardCoastalInundation",
            overrides: {
              singleAnaAsset: {
                cartesian: {
                  xAxis: {
                    step: 20,
                  },
                },
              },
            },
          },
        },
        {
          id: v4(),
          type: "chart",
          mode: "line",
          width: "triple",
          template: {
            type: "allHazards",
            overrides: {
              singleAnaAsset: {
                cartesian: {
                  xAxis: {
                    step: 5,
                  },
                },
              },
            },
          },
        },
      ],
    },
  ],
};

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {
    addDashboard: (state, action: PayloadAction<Dashboard>) => {
      state.dashboards.push(action.payload);
    },
    removeDashboard: (state, action: PayloadAction<string>) => {
      state.dashboards = state.dashboards.filter(({ id }) => id !== action.payload);
    },
    updateDashboard: (state, action: PayloadAction<Dashboard>) => {
      state.dashboards = state.dashboards.map((dashboard) => {
        return action.payload.id === dashboard.id ? action.payload : dashboard;
      });
    },
  },
});

export const { addDashboard, removeDashboard, updateDashboard } = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
