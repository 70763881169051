import { merge } from "lodash/fp";
import { Translation } from "../../types";
import enAu from "../en-AU";
import terms from "./terms";

/**
 * English (US) translations - extends English (AU).
 */
const enUs: Translation = merge(enAu, {
  terms,
} as Partial<Translation>);

export default enUs;
