import { Tool } from "@/types/tools";
import { BookOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { useEffect } from "react";
const buildSidebarRedirect = () => "/glossary/_";
const Render = () => {
  const router = useRouter();
  useEffect(() => {
    router.replace(buildSidebarRedirect());
  }, []);
  return <></>;
};
export default {
  id: "glossary",
  category: "reference",
  keyPrefix: "reference.glossary",
  icon: <BookOutlined />,
  render: () => <Render />,
  buildSidebarRedirect
} as Tool;