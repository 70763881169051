import { formatReadableDateTime } from "@/utils/datetime";
import { Col, Row, Typography } from "antd";
import { JobSchema } from "../types/JobSchema";
const {
  Text
} = Typography;
interface JobInformationProps<T extends JobSchema> {
  schema: Required<T>;
}
export const JobInformation = <T extends JobSchema,>({
  schema
}: JobInformationProps<T>) => {
  const startTimeText = schema.startTime > 0 ? `${formatReadableDateTime(new Date(schema.startTime))}` : "Unavailable";
  return <Col span={24} data-sentry-element="Col" data-sentry-component="JobInformation" data-sentry-source-file="JobInformation.tsx">
      <Row data-sentry-element="Row" data-sentry-source-file="JobInformation.tsx">{schema.name}</Row>
      <Row data-sentry-element="Row" data-sentry-source-file="JobInformation.tsx">
        <Text type="secondary" data-sentry-element="Text" data-sentry-source-file="JobInformation.tsx">Started: {startTimeText}</Text>
      </Row>
    </Col>;
};