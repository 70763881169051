import { decryptData } from "@/features/cryptography";
import { store, StoreState } from "@/store";
import { FacadesBatchPostResponse } from "@/store/services/facades/types";
import { supabaseApi, useLazyGetUnprocessedPortfolioAssetCountQuery, useLazyGetUnprocessedPortfolioAssetIdsQuery } from "@/store/services/supabase";
import { dispatchWithRetries } from "@/store/services/supabase/utils";
import { portfolioExportActions } from "@/store/slices/portfolioExport";
import { PortfolioExportJobActions } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobActions";
import { PortfolioExportJobErrorModal } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobErrorModal";
import { PortfolioExportJobStatus } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobStatus";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { getPortfolioExportPostAction } from "@/tools/aggregate/portfolio-export/utils/post";
import { formatRelativeDate } from "@/utils/datetime";
import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { Button, Col, Row } from "antd";
import _ from "lodash";
import { cloneDeep } from "lodash/fp";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./PortfolioExportJobInformation.module.scss";
interface PortfolioExportJobInformationProps {
  onDelete: () => void;
  schema: PortfolioExportJobSchema;
  updateSchema: (schema: PortfolioExportJobSchema) => void;
  onError: (err: string | string[], portfolioId: string) => void;
}
export const PortfolioExportJobInformation = ({
  onDelete,
  schema,
  updateSchema,
  onError
}: PortfolioExportJobInformationProps) => {
  const [kp] = useKeyPair();
  const [getUnprocessedPortfolioAssets] = useLazyGetUnprocessedPortfolioAssetIdsQuery();
  const [getUnprocessedPortfolioAssetCount] = useLazyGetUnprocessedPortfolioAssetCountQuery();
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (schema.status !== "uploading" || !kp.user) return;
    getAssets();
  }, [kp.user]);
  const getAssets = async () => {
    const data = await getUnprocessedPortfolioAssets({
      portfolioId: schema.id,
      length: 100
    }).unwrap();
    const {
      data: unprocessedCount
    } = await getUnprocessedPortfolioAssetCount({
      portfolioId: schema.id
    });
    if (data.length !== 0) {
      if (unprocessedCount) {
        store.dispatch(portfolioExportActions.setNewRunTotalInBatchTo({
          id: schema.id,
          count: unprocessedCount
        }));
      }
      updateSchema({
        ...cloneDeep(schema),
        status: "uploading"
      });
      const decryptedData = await Promise.all([...data.map(async d => {
        // Todo: Transform to the shape for batch instead of removing an extra property
        const _parsed = JSON.parse(await decryptData(kp.user!.keyPair, d.data || ""));
        return JSON.stringify(_.omit(_parsed, "userSuppliedId"));
      })]);
      const supabaseIds = data.map(d => ({
        id: d.supabaseId
      }));
      await sendBatch(decryptedData).then(async () => await markAsProcessed(supabaseIds)).catch(async err => onError(err, schema.id));
      await getAssets();
    } else {
      updateSchema({
        ...cloneDeep(schema),
        status: "processing"
      });
    }
  };
  const sendBatch = async (batch: string[]) => {
    console.log("[PORTFOLIO] sendBatch");
    const action = getPortfolioExportPostAction(batch);
    const data = await dispatchWithRetries<FacadesBatchPostResponse>({
      action,
      maxRetries: 5,
      timeout: 5000
    });
    if (data.errors > 0) {
      if (data.error_summary) {
        throw data.error_summary.map(({
          title,
          detail
        }) => {
          if (title === "Json does not match JsonSchema") {
            return "The uploaded file does not match the expected format.";
          }
          return `${title}: ${detail}`;
        });
      }
      throw ["Errors occured when uploading data - please try again"];
    }
    return data;
  };
  const markAsProcessed = async (batch: {
    id: string;
  }[]) => {
    console.log("[PORTFOLIO] markAsProcessed ");
    const action = supabaseApi.endpoints.setPortfolioAssetIsProcessedToTrue.initiate(batch);
    return await dispatchWithRetries<null>({
      action,
      maxRetries: 5,
      timeout: 5000
    });
  };
  return <Col span={24} className={styles.rowContainer} data-sentry-element="Col" data-sentry-component="PortfolioExportJobInformation" data-sentry-source-file="PortfolioExportJobInformation.tsx">
      <Row style={{
      justifyContent: "space-between",
      alignItems: "center"
    }} data-sentry-element="Row" data-sentry-source-file="PortfolioExportJobInformation.tsx">
        <Col data-sentry-element="Col" data-sentry-source-file="PortfolioExportJobInformation.tsx">
          <div className={styles.portfolioName}>
            <div className={styles.statusIcon}>
              <PortfolioExportJobStatus schema={schema} iconOnly data-sentry-element="PortfolioExportJobStatus" data-sentry-source-file="PortfolioExportJobInformation.tsx" />
            </div>
            <span>{schema.name}</span>
            <PortfolioExportJobStatus schema={schema} data-sentry-element="PortfolioExportJobStatus" data-sentry-source-file="PortfolioExportJobInformation.tsx" />
          </div>
          <PortfolioExportJobCounts schema={schema} setShowError={setShowError} data-sentry-element="PortfolioExportJobCounts" data-sentry-source-file="PortfolioExportJobInformation.tsx" />
        </Col>
        <Col data-sentry-element="Col" data-sentry-source-file="PortfolioExportJobInformation.tsx">
          <PortfolioExportJobActions onDeleteJob={onDelete} schema={schema} data-sentry-element="PortfolioExportJobActions" data-sentry-source-file="PortfolioExportJobInformation.tsx" />
        </Col>
      </Row>
      {kp.user && <PortfolioExportJobErrorModal open={showError} schema={schema} userKey={kp.user} onCancel={() => setShowError(false)} />}
    </Col>;
};
type PortfolioExportJobCountsType = {
  schema: PortfolioExportJobSchema;
  setShowError: Dispatch<SetStateAction<boolean>>;
};
const PortfolioExportJobCounts = ({
  schema,
  setShowError
}: PortfolioExportJobCountsType) => {
  const startTimeText = schema.startTime > 0 ? `${formatRelativeDate(new Date(schema.startTime))}` : "Unavailable";
  const uploadCount = useSelector((state: StoreState) => state.portfolioExport.newRun.totalInBatch)[schema.id] || 0;
  const ProcessingCount = () => {
    if (schema.status !== "processing") return <></>;
    return <span data-sentry-component="ProcessingCount" data-sentry-source-file="PortfolioExportJobInformation.tsx">{schema.assets.totalCount - schema.assets.processedCount} processing</span>;
  };
  const UploadedCount = () => {
    if (schema.status !== "uploading") return <></>;
    return <span data-sentry-component="UploadedCount" data-sentry-source-file="PortfolioExportJobInformation.tsx">{schema.assets.totalCount - uploadCount} uploaded</span>;
  };
  schema.status === "uploading" ? <span>{schema.assets.totalCount - uploadCount} uploaded</span> : <></>;
  const ErrorCount = () => {
    if (!schema.assets.errorCount) return <></>;
    return <span data-sentry-component="ErrorCount" data-sentry-source-file="PortfolioExportJobInformation.tsx">
        <Button type="link" className={styles.viewErrorsBtn} onClick={() => setShowError(true)} data-sentry-element="Button" data-sentry-source-file="PortfolioExportJobInformation.tsx">
          Show errors ({schema.assets.errorCount})
        </Button>
      </span>;
  };
  return <div className={styles.smallText} data-sentry-component="PortfolioExportJobCounts" data-sentry-source-file="PortfolioExportJobInformation.tsx">
      <span>{startTimeText}</span>
      <span>{schema.assets.totalCount} assets</span>
      <ProcessingCount data-sentry-element="ProcessingCount" data-sentry-source-file="PortfolioExportJobInformation.tsx" />
      <UploadedCount data-sentry-element="UploadedCount" data-sentry-source-file="PortfolioExportJobInformation.tsx" />
      <ErrorCount data-sentry-element="ErrorCount" data-sentry-source-file="PortfolioExportJobInformation.tsx" />
    </div>;
};