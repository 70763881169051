import { ProcessStatusIcon } from "@/components/status/ProcessStatusTag";
import { StoreState } from "@/store";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
interface PortfolioExportJobStatusProps {
  schema: PortfolioExportJobSchema;
  iconOnly?: boolean;
}
export const PortfolioExportJobStatus = ({
  schema,
  iconOnly = false
}: PortfolioExportJobStatusProps) => {
  const {
    t
  } = useTranslation("terms", {
    keyPrefix: "status"
  });
  let customText = "";
  const hasErrors = schema.assets.errorCount > 0;
  const status = hasErrors && schema.status === "completed" ? "completed_with_errors" : schema.status;
  const {
    processedCount = 0,
    totalCount = 0
  } = schema.assets;
  const uploadCount = useSelector((state: StoreState) => state.portfolioExport.newRun.totalInBatch)[schema.id] || 0;
  if (iconOnly) {
    return <ProcessStatusIcon status={status} />;
  }
  const toPercent = (value: number): string => {
    return (value * 100 || 0).toFixed(1);
  };
  switch (status) {
    case "processing":
    case "running":
      customText = toPercent(processedCount / totalCount) + "% PROCESSED";
      return <Tag color="blue">{customText || t(status).toUpperCase()}</Tag>;
    case "uploading":
      customText = toPercent((totalCount - uploadCount) / totalCount) + "% UPLOADED";
      return <Tag color="orange">{customText || t(status).toUpperCase()}</Tag>;
    default:
      return <></>;
  }
};