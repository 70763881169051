import { Modal, ModalProps } from "antd";
import { JobSchema } from "../types/JobSchema";
export interface JobFailModalProps<T extends JobSchema> extends ModalProps {
  schema: Required<T>;
}
export const JobFailModal = <T extends JobSchema,>({
  children,
  ...props
}: JobFailModalProps<T>) => {
  return <Modal footer={<></>} {...props} data-sentry-element="Modal" data-sentry-component="JobFailModal" data-sentry-source-file="JobFailModal.tsx">
      {children}
    </Modal>;
};