import { facadesApi } from "@/store/services/facades";
import { AbcRequest, AbcResponse, RawAbcResponse } from "@/store/services/facades/types/abc";

export const abcFacadesApi = facadesApi.injectEndpoints({
  endpoints: (build) => ({
    getAbcRiskBands: build.query<AbcResponse, AbcRequest>({
      query: (body) => {
        return {
          method: "POST",
          url: "abc",
          headers: {
            Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_API_KEY}`,
          },
          body,
        };
      },
      transformErrorResponse: (error) => {
        const errorData = error.data as {
          title: string;
          status: number;
          detail: string;
          type: string;
        };
        return {
          message: errorData.detail || "Something unexpected happened. Please try again.",
          status: errorData.status,
        };
      },
      transformResponse: (response: RawAbcResponse): AbcResponse => {
        return {
          mode: "abc",
          metadata: response.metadata,
          rcp85: {
            ...response,
          },
        };
      },
    }),
  }),
});
