import { z } from "zod";

export const latSchema = z.number().gte(-90).lte(90);
export const lngSchema = z.number().gte(-180).lte(180);

export const latLngSchema = z.object({
  lat: latSchema,
  lng: lngSchema,
});

export type LatLng = z.infer<typeof latLngSchema>;

export const invalidLatLng: LatLng = {
  lat: Infinity,
  lng: Infinity,
};
