import { store } from "@/store";
import { supabaseApi } from "@/store/services/supabase";
import { unwrapKeyPair } from "@/utils/hooks/useKeyPair/utils";

export const getFromDatabase = async ({ password }: { password: string }) => {
  console.log("getFromDatabase");
  let value;
  const { data, error } = await store.dispatch(supabaseApi.endpoints.getKeyPair.initiate());

  if (data) {
    try {
      const unwrapped = await unwrapKeyPair({ data, password });
      value = {
        data: unwrapped,
        error: null,
      };
    } catch {
      value = {
        data: null,
        error: "Something went wrong. Your password may be incorrect.",
      };
    }
  } else {
    value = {
      data: null,
      error,
    };
  }

  console.log("getFromDatabase value: ", value);

  return value;
};
