import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";

/**
 * Button formatted to be a proper download link.
 */
export const DownloadButton = ({
  children,
  ...props
}: ButtonProps) => {
  return <Button {...props} data-sentry-element="Button" data-sentry-component="DownloadButton" data-sentry-source-file="DownloadButton.tsx">
      <CloudDownloadOutlined data-sentry-element="CloudDownloadOutlined" data-sentry-source-file="DownloadButton.tsx" />
      {children}
    </Button>;
};