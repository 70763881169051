import { getTrackingConfig } from "@/features/branding";
import supabase from "@/features/supabase";
import { store } from "@/store";
import { internalApi } from "@/store/services/internal";
import { supabaseApi } from "@/store/services/supabase";
import { User } from "@supabase/supabase-js";
interface ToolUsageData {
  id: string;
  type: string;
  count: number;
  portfolioId?: string;
  archetype?: string;
}

// TODO: Replace with common getOrgs function when exists.
const getOrgs = async () => {
  const {
    data
  } = await supabase.from("orgs").select("*");
  return data;
};
export default async function logToolUsage(data: ToolUsageData) {
  const {
    user
  } = (await supabase.auth.getUser()).data;
  await Promise.all([logToolUsageInHubspot(data, user), logToolUsageInDb(data, user)]);
}
const logToolUsageInHubspot = async (data: ToolUsageData, user: User | null) => {
  const {
    hubspot
  } = getTrackingConfig();
  if (!hubspot.enabled || !hubspot.allowToolTracking) {
    return;
  }

  // Determine what organisation we will use.
  const organisationList = await getOrgs();
  const organisation = organisationList?.length ? organisationList[0].name || organisationList[0].id : "<no_org>";

  // Send information to Hubspot form.
  try {
    const action = internalApi.endpoints.logProductUsage.initiate({
      organisation,
      product: data.type,
      portfolioId: data.portfolioId,
      username: user?.email || "",
      amount: data.count,
      pipelineId: hubspot.pipeline.id,
      pipelineStage: hubspot.pipeline.stage,
      domain: window.location.hostname
    });
    await store.dispatch(action);
  } catch (err) {
    console.log("Failed to send tracking information to HubSpot:", err);
  }
};
const logToolUsageInDb = async (data: ToolUsageData, user: User | null) => {
  const {
    id: groupId
  } = await store.dispatch(supabaseApi.endpoints.getGroupId.initiate({
    userId: user?.id || ""
  })).unwrap();
  const action = supabaseApi.endpoints.logToolUsage.initiate({
    tool_id: data.id,
    count: data.count,
    group_id: groupId,
    user_id: user?.id || "",
    archetype: data.archetype || ""
  });
  const response = await store.dispatch(action);
  if ("error" in response) {
    console.log("Failed to log tool usage in DB:", response.error);
  }
};