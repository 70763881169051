import "@/features/internationalisation";
import "leaflet/dist/leaflet.css";
import type { AppProps } from "next/app";
import "../styles/globals.css";
import supabase from "@/features/supabase";
import { Session, SessionContextProvider, useSessionContext } from "@supabase/auth-helpers-react";
import { NextPage } from "next";
import Head from "next/head";
import { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import { CustomConfigProvider } from "@/components/layouts/CustomConfigProvider";
import { HubLayout } from "@/components/layouts/HubLayout";
import { Loading } from "@/components/overlays/Loading";
import { getAppName, getConfig, getTheme } from "@/features/branding";
import i18n from "@/features/internationalisation";
import { store } from "@/store";
import { internalApi } from "@/store/services/internal";
import { KeyPairProvider } from "@/utils/hooks/useKeyPair/KeyPairProvider";
import { HubPlausibleProvider } from "@/features/tracking";
import "@fontsource-variable/jetbrains-mono";
const UserLoadingWrapper = ({
  children
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {
    isLoading
  } = useSessionContext();
  return isLoading ? <Loading /> : children;
};
export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};
type AppPropsWithLayout = AppProps & {
  initialSession: Session;
  Component: NextPageWithLayout;
};
function MyApp({
  Component,
  pageProps
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: ReactNode) => <HubLayout>{page}</HubLayout>);

  /** Apply branding translation overrides */
  useEffect(() => {
    const language = i18n.language;
    const overrides: Record<string, any> = getTheme().translationOverrides?.[language] || {};
    for (const key of Object.keys(overrides)) {
      i18n.addResourceBundle(language, key, overrides[key], true, true);
    }
  }, []);
  useEffect(() => {
    if (getConfig().featureSet.docs.contextualElementTutorials.enabled) {
      store.dispatch(internalApi.endpoints.getCmsPosts.initiate());
    }
  });
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>{getAppName()}</title>
        <meta name="description" content="Your home of climate insight" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HubPlausibleProvider data-sentry-element="HubPlausibleProvider" data-sentry-source-file="_app.tsx">
        <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
          <CustomConfigProvider data-sentry-element="CustomConfigProvider" data-sentry-source-file="_app.tsx">
            <SessionContextProvider supabaseClient={supabase} data-sentry-element="SessionContextProvider" data-sentry-source-file="_app.tsx">
              <KeyPairProvider data-sentry-element="KeyPairProvider" data-sentry-source-file="_app.tsx">
                <UserLoadingWrapper data-sentry-element="UserLoadingWrapper" data-sentry-source-file="_app.tsx">
                  <>{getLayout(<Component {...pageProps} />)}</>
                </UserLoadingWrapper>
              </KeyPairProvider>
            </SessionContextProvider>
          </CustomConfigProvider>
        </Provider>
      </HubPlausibleProvider>
    </>;
}
export default MyApp;