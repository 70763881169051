export const KEY_PAIR_STORE = {
  user: {
    public: "userPublicKey",
    private: "userPrivateKey",
    salt: "userSalt",
  },
  group: {
    public: "groupPublicKey",
    private: "groupPrivateKey",
    salt: "groupSalt",
  },
};
