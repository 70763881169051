import { ArchetypeConfig } from "../../schema";
import { defaultInputValues } from "../input_values";

const { aud } = defaultInputValues;

/**
 * Archetypes used specifically for ABC risk bands.
 */
export const defaultAbcArchetypes: ArchetypeConfig[] = [
  {
    id: "house",
    name: "House",
    value: "SimpleHouse",
    category: "residential",
    description: "Low-rise residential urban dwelling (simplified - meaning reduced archetype elements)",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.residential,
  },
  {
    id: "townhouse",
    name: "Townhouse",
    value: "MediumDensityAttached",
    category: "residential",
    description: "Residential dwellings sharing a central wall with adjacent dwellings",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.residential,
  },
  {
    id: "apartment",
    name: "Apartment",
    value: "HighRiseFlat",
    category: "residential",
    description: "High-rise residential block of flats",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.residential,
  },
];
