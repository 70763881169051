import { DownloadButton } from "@/components/buttons/DownloadButton";
import { HubMapPointsDisplay } from "@/features/maps";
import { logToolUsage } from "@/features/tracking";
import styles from "@/styles/pages/quick/batch.module.scss";
import { UnmatchedType } from "@/tools/risk-bands/batch";
import { GeocodeWithResult, GeocodeWithSingleRcp, MultipleResultsTable } from "@/tools/risk-bands/components/MultipleResultsTable";
import { UnmatchedAddressesCollapse } from "@/tools/risk-bands/components/UnmatchedAddressesCollapse";
import { RiskBandsAdvancedSettingsValues } from "@/tools/risk-bands/types";
import { downloadRiskBandsCsv } from "@/tools/risk-bands/utils/download";
import { Rcp, rcpValues } from "@/types/rcp";
import { BarsOutlined, GlobalOutlined } from "@ant-design/icons";
import { Col, Segmented, Select } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
type ScenarioOptsType = {
  value: Rcp;
  label: string;
}[];
interface Step3Props {
  results: GeocodeWithResult[];
  unmatched: UnmatchedType[];
  advancedSettings: Partial<RiskBandsAdvancedSettingsValues>;
  setRefs: (ref: RiskBandsBatchStep3Refs) => void;
  onRetry: () => void;
}
export interface RiskBandsBatchStep3Refs {
  downloadCsv: MutableRefObject<null>;
  map: MutableRefObject<null>;
  rcp: MutableRefObject<null>;
  tabToggler: MutableRefObject<null>;
}
export const RiskBandsBatchStep3 = ({
  results,
  unmatched,
  advancedSettings,
  setRefs,
  onRetry
}: Step3Props) => {
  const [option, setOption] = useState("map");
  const [response, setResponse] = useState<GeocodeWithSingleRcp[]>();
  const [scenario, setScenario] = useState<Rcp>("rcp85");
  const {
    t
  } = useTranslation();
  const {
    t: terms
  } = useTranslation("terms");
  const downloadCsvRef = useRef(null);
  const mapRef = useRef(null);
  const rcpRef = useRef(null);
  const tabTogglerRef = useRef(null);
  useEffect(() => {
    setRefs({
      downloadCsv: downloadCsvRef,
      map: mapRef,
      rcp: rcpRef,
      tabToggler: tabTogglerRef
    });
  }, [downloadCsvRef, mapRef, rcpRef, tabTogglerRef]);
  const scenarioOpts: ScenarioOptsType = rcpValues.map(rcp => {
    return {
      value: rcp,
      label: terms(`scenarios.${rcp}.medium`)
    };
  });
  const isWordy = () => {
    if (results.length && results[0].result && results[0].result.mode) {
      return results[0].result.mode !== "abc";
    }
    return false;
  };
  useEffect(() => {
    logToolUsage({
      id: "riskBands.batch",
      type: "risk_band_quick_batch",
      count: results.length,
      archetype: advancedSettings.archetype
    });
  }, []);
  useEffect(() => {
    const res = results.map(asset => {
      const result = asset.result.mode !== "abc" ? asset.result[scenario] : asset.result["rcp85"];
      return {
        geocode: asset.geocode,
        result,
        metadata: asset.result.metadata
      };
    });
    setResponse(res);
  }, [scenario]);
  const handleChange = (value: SegmentedValue) => setOption(value.valueOf() as string);
  return <>
      <div style={{
      background: "var(--color-gray-50)",
      border: "1px solid var(--color-gray-100)",
      padding: 16,
      borderRadius: 8
    }}>
        <div style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 8,
        width: "100%"
      }}>
          <Segmented ref={tabTogglerRef} onChange={handleChange} options={[{
          value: "map",
          label: t("terms:map"),
          icon: <GlobalOutlined />,
          title: "Map"
        }, {
          value: "list",
          label: t("terms:list"),
          icon: <BarsOutlined />,
          title: "List"
        }]} value={option} style={{
          maxWidth: 400
        }} data-sentry-element="Segmented" data-sentry-source-file="BatchStep3.tsx" />
          {isWordy() && <span ref={rcpRef}>
              <Select onChange={value => setScenario(value)} options={scenarioOpts} defaultValue={scenario} style={{
            maxWidth: "360px",
            width: "100%",
            textAlign: "left"
          }} />
            </span>}
        </div>
        {!!response && <>
            {option === "map" && <Col ref={mapRef} span={24} key={scenario} className={styles.fadeIn}>
                <HubMapPointsDisplay className={styles.map} points={response.map(({
            geocode,
            result,
            metadata
          }) => ({
            name: geocode.name,
            input: geocode.input,
            latLng: geocode.latLng,
            response: result,
            quality: geocode.quality,
            metadata
          }))} />
              </Col>}

            {option === "list" && <Col span={24} key={scenario} className={styles.fadeIn}>
                <MultipleResultsTable aria-label="Results Table" data={response} />
              </Col>}

            {unmatched.length > 0 && <Col span={24} style={{
          marginTop: 16
        }}>
                <UnmatchedAddressesCollapse addresses={unmatched} onRetry={onRetry} />
              </Col>}
          </>}
      </div>

      <div style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 16,
      width: "100%"
    }}></div>
      <div style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
        <span ref={downloadCsvRef}>
          <DownloadButton type="primary" onClick={() => downloadRiskBandsCsv({
          advancedSettings,
          filename: "quick-batch.csv",
          results
        })} data-sentry-element="DownloadButton" data-sentry-source-file="BatchStep3.tsx">
            {t("actions:download.csv")}
          </DownloadButton>
        </span>
      </div>
    </>;
};