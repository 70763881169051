import { HubFormItem } from "@/components/inputs/HubFormItem";
import { buildYearListFromString, humaniseYearList } from "@/utils/years";
import { Col, Input, Row, Segmented, Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
const defaultYearRange = "1990-2100";
const allSteps = Array(110).fill(0).map((_, index) => index + 1);
const allYears = buildYearListFromString(defaultYearRange);
interface YearsInputProps {
  setYears: (years: number[]) => void;
  years: number[];
}

/**
 *
 * @param param0 -
 * @returns
 */
export const YearsInput = ({
  years,
  setYears
}: YearsInputProps) => {
  const [yearsHasError, setYearsHasError] = useState(false);
  const [yearsHelp, setYearsHelp] = useState(humaniseYearList(years));
  const [mode, setMode] = useState<"stepped" | "custom">("stepped");
  const handleSetYears = (input: string) => {
    try {
      const list = buildYearListFromString(input);
      setYears(list);
      setYearsHasError(false);
      setYearsHelp("Will use " + humaniseYearList(list).toLowerCase());
    } catch ({
      message
    }) {
      setYears([]);
      setYearsHasError(true);
      setYearsHelp(message as string);
    }
  };
  return <HubFormItem error={yearsHasError} help={yearsHelp} label="Scenario Years" data-sentry-element="HubFormItem" data-sentry-component="YearsInput" data-sentry-source-file="YearsInput.tsx">
      <Segmented block options={[{
      value: "stepped",
      label: "Stepped"
    }, {
      value: "custom",
      label: "Custom"
    }]} value={mode} onChange={value => setMode(value.valueOf() as any)} style={{
      marginBottom: ".5rem"
    }} data-sentry-element="Segmented" data-sentry-source-file="YearsInput.tsx" />
      {mode === "stepped" && <DropdownYearListInput onChange={handleSetYears} />}
      {mode === "custom" && <CustomYearListInput onChange={handleSetYears} />}
    </HubFormItem>;
};
interface YearInputSubComponentProps {
  onChange: (value: string) => void;
}
const CustomYearListInput = ({
  onChange
}: YearInputSubComponentProps) => {
  const [input, setInput] = useState(defaultYearRange + "s10");
  useEffect(() => onChange(input), [input]);
  return <Input value={input} onChange={event => setInput(event.target.value)} data-sentry-element="Input" data-sentry-component="CustomYearListInput" data-sentry-source-file="YearsInput.tsx" />;
};
const DropdownYearListInput = ({
  onChange
}: YearInputSubComponentProps) => {
  const [startYear, setStartYear] = useState(1990);
  const [endYear, setEndYear] = useState(2100);
  const [step, setStep] = useState(10);
  useEffect(() => {
    onChange(`${startYear}-${endYear}s${step}`);
  }, [startYear, endYear, step]);
  return <Row gutter={16} data-sentry-element="Row" data-sentry-component="DropdownYearListInput" data-sentry-source-file="YearsInput.tsx">
      <Col span={24} sm={8} data-sentry-element="Col" data-sentry-source-file="YearsInput.tsx">
        <HubFormItem label="Start Year" data-sentry-element="HubFormItem" data-sentry-source-file="YearsInput.tsx">
          <YearSelect onChange={setStartYear} value={startYear} data-sentry-element="YearSelect" data-sentry-source-file="YearsInput.tsx" />
        </HubFormItem>
      </Col>
      <Col span={24} sm={8} data-sentry-element="Col" data-sentry-source-file="YearsInput.tsx">
        <HubFormItem label="End Year" data-sentry-element="HubFormItem" data-sentry-source-file="YearsInput.tsx">
          <YearSelect onChange={setEndYear} value={endYear} data-sentry-element="YearSelect" data-sentry-source-file="YearsInput.tsx" />
        </HubFormItem>
      </Col>
      <Col span={24} sm={8} data-sentry-element="Col" data-sentry-source-file="YearsInput.tsx">
        <HubFormItem label="Step" data-sentry-element="HubFormItem" data-sentry-source-file="YearsInput.tsx">
          <StepSelect onChange={setStep} value={step} data-sentry-element="StepSelect" data-sentry-source-file="YearsInput.tsx" />
        </HubFormItem>
      </Col>
    </Row>;
};
const stepVerboseTerms = [{
  step: 1,
  term: "Annual"
}, {
  step: 2,
  term: "Biennial"
}, {
  step: 10,
  term: "Decadal"
}];
const StepSelect = (props: SelectProps) => {
  return <Select {...props} data-sentry-element="Select" data-sentry-component="StepSelect" data-sentry-source-file="YearsInput.tsx">
      {allSteps.map(step => {
      const term = stepVerboseTerms.find(data => data.step === step);
      return <Select.Option key={step} value={step}>
            {step} {term && `(${term.term})`}
          </Select.Option>;
    })}
    </Select>;
};
const YearSelect = (props: SelectProps) => {
  return <Select {...props} data-sentry-element="Select" data-sentry-component="YearSelect" data-sentry-source-file="YearsInput.tsx">
      {allYears.map(year => {
      return <Select.Option key={year} value={year}>
            {year}
          </Select.Option>;
    })}
    </Select>;
};