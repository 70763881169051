import type { Salt } from "../../types";

const encoder = new TextEncoder();

/**
 * Imports a key from an input string which will be used to derive a useful key.
 * @param input - The string from which to derive a key. Usually a password.
 * @returns A key with which to derive a proper key.
 */
async function importKeyFromString(input: string) {
  return await crypto.subtle.importKey(
    "raw",
    encoder.encode(input),
    {
      name: "PBKDF2",
    },
    false,
    ["deriveKey"]
  );
}

async function deriveSecretKeyFromImportKey(importKey: CryptoKey, salt: Salt) {
  return await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      hash: "SHA-256",
      salt,
      iterations: 600000,
    },
    importKey,
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
  );
}

export function generateRandomSalt(): Salt {
  return crypto.getRandomValues(new Uint8Array(16));
}

export async function generateSecretKey(input: string, salt: Salt) {
  const importKey = await importKeyFromString(input);
  return await deriveSecretKeyFromImportKey(importKey, salt);
}
