import { Translation } from "../../types";
import actions from "./actions";
import authentication from "./authentication";
import features from "./features";
import languages from "./languages";
import meta from "./meta";
import pages from "./pages";
import terms from "./terms";
import tools from "./tools";
import units from "./units";
import widgets from "./widgets";

/**
 * English (AU) translations - default for English and overall.
 */
const enAu: Translation = {
  actions,
  authentication,
  features,
  languages,
  meta,
  pages,
  terms,
  tools,
  units,
  widgets,
};

export default enAu;
