import { Tour } from "antd";
import { TourProps } from "antd/lib";
import { useEffect } from "react";
type StateRecord = Record<string, any>;
export interface InteractiveTutorialProps<T extends StateRecord> extends TourProps {
  /** Whether to show Tour in wide mode. */
  isWide?: boolean;
  /** Informs to set state in the parent component. */
  setState: (state: T) => void;
}
export function InteractiveTutorial<T extends StateRecord>(props: InteractiveTutorialProps<T>) {
  // Ensure that the Tour is positioned correctly - important for CSS overrides.
  useEffect(() => {
    if (props.open && typeof window !== "undefined") {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 80); // Not happy with magic number - there are better solutions (reacting to render), but this has to do before launch.
    }
  }, [props.open]);
  return <Tour disabledInteraction rootClassName={props.isWide ? "wide" : undefined} {...props} data-sentry-element="Tour" data-sentry-component="InteractiveTutorial" data-sentry-source-file="InteractiveTutorial.tsx" />;
}