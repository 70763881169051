import { Archetype } from "@/lib/crg_apis/types/archetype";
import { Material } from "@/lib/crg_apis/types/materials";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { DefaultOptionType } from "antd/lib/select";

interface AcrobaseHierarchyResponse {
  [key: string]:
    | AcrobaseHierarchyResponse
    | {
        name: string;
        acronym: string;
      }[];
}

interface ArchetypeHierarchyOption {
  label: string;
  value: string;
  children?: ArchetypeHierarchyOption[];
}

export interface MaterialOption extends DefaultOptionType {
  groups: string[];
}

const buildHierarchyOptions = (input: AcrobaseHierarchyResponse): ArchetypeHierarchyOption[] => {
  if (Array.isArray(input)) {
    return input.map((item) => ({
      label: item.name,
      value: item.acronym,
    }));
  }

  return Object.keys(input).map((key) => ({
    label: key,
    value: key,
    children: buildHierarchyOptions(input[key] as AcrobaseHierarchyResponse),
  }));
};

export const acrobaseApi = createApi({
  reducerPath: "acrobase",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://acrobase.xdi.systems/v1/",
  }),
  endpoints: (build) => ({
    getArchetype: build.query<Archetype, string>({
      query: (acronym) => {
        return {
          url: `archetype?acronym=${acronym}&include=elements+materials+fields&hazards-as-list&expand_aliases=true`,
        };
      },
    }),

    getArchetypeHierarchy: build.query<ArchetypeHierarchyOption[], void>({
      query: () => {
        return {
          url: "archetype_hierarchy",
        };
      },
      transformResponse: (response: AcrobaseHierarchyResponse) => {
        // Remove the first layer of the onion.
        const peeledResponse = Object.keys(response).reduce<AcrobaseHierarchyResponse>((list, key) => {
          const newList = { ...list };

          const values = response[key] as AcrobaseHierarchyResponse;
          for (const newKey of Object.keys(values)) {
            newList[newKey] = newList[newKey] ? { ...newList[newKey], ...values[newKey] } : values[newKey];
          }

          return newList;
        }, {});

        return buildHierarchyOptions(peeledResponse);
      },
    }),

    getMaterialList: build.query<MaterialOption[], void>({
      query: () => {
        return "material_list?expand_aliases=true";
      },
      transformResponse: (response: Material[]) => {
        return [
          { value: "N/A", groups: [], label: "Not Included" },
          ...response.map(({ id, allowed_groups, name }) => ({
            value: id,
            groups: allowed_groups,
            label: name,
          })),
        ];
      },
    }),
  }),
});
