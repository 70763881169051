import { StructuralSettings } from "../../types/settings";

/**
 * db/structural_settings.yaml
 */
type StructuralSettingsYaml = StructuralSettings["files"]["db/structural_settings.yaml"];

export const structuralSettingsElements: StructuralSettingsYaml = {
  method: "default",
  sections: {
    inputs: "all",
    metadata: "all",
    results: {
      damage: ["total", "byHazard", "byHazardElement"],
      failure: ["total", "byHazard", "byHazardElement"],
      financial: ["totalRiskCost", "newValue"],
      kpi: ["none"],
    },
  },
};

export const structuralSettingsFull: StructuralSettingsYaml = {
  method: "default",
  sections: {
    inputs: "all",
    metadata: "all",
    results: {
      damage: ["total", "byHazard", "byHazardElement"],
      failure: ["total", "byHazard", "byHazardElement"],
      financial: ["totalRiskCost", "newValue", "reducedValue"],
      kpi: [
        "HeatStress",
        "Seniors",
        "Child",
        "MentallyDisabled",
        "PhysicallyDisabled",
        "ExcessHeatDeaths",
        "ResidentialCustomer",
        "CommercialCustomer",
        "IndustrialCustomer",
        "CriticalCustomer",
        "OtherCustomer",
        "HoursLostUnpaid",
        "HoursLostPaid",
        "HeatWaveDays",
      ],
    },
  },
};

export const structuralSettingsKpis: StructuralSettingsYaml = {
  method: "default",
  sections: {
    inputs: "all",
    metadata: "all",
    results: {
      damage: ["total", "byHazard"],
      failure: ["total", "byHazard"],
      financial: ["totalRiskCost", "newValue"],
      kpi: [
        "HeatStress",
        "Seniors",
        "Child",
        "MentallyDisabled",
        "PhysicallyDisabled",
        "ExcessHeatDeaths",
        "ResidentialCustomer",
        "CommercialCustomer",
        "IndustrialCustomer",
        "CriticalCustomer",
        "OtherCustomer",
        "HoursLostUnpaid",
        "HoursLostPaid",
        "HeatWaveDays",
      ],
    },
  },
};

export const structuralSettingsStandard: StructuralSettingsYaml = {
  method: "default",
  sections: {
    inputs: "all",
    metadata: "all",
    results: {
      damage: ["total", "byHazard"],
      failure: ["total", "byHazard"],
      financial: ["totalRiskCost", "newValue"],
      kpi: ["none"],
    },
  },
};
