import { Widgets } from "../../types/widgets";

const widgets: Widgets = {
  addressAutocomplete: {
    prompt: "Enter asset address",
  },
  map: {
    hints: {
      click: "Hint: Click map to move the pin if the search result is not accurate.",
    },
  },
  notifications: {
    title: "Notifications",
    hints: {
      empty: "No new notifications.",
    },
  },
  textInput: {
    addresses: {
      countOverLimit: "Too many addresses entered ({{count}}/{{max}}).",
      countWithinLimit: "{{count}}/{{max}} addresses entered.",
      placeholder: "Paste a list of assets here (maximum {{count}})",
    },
  },
  upload: {
    csv: {
      hints: {
        first10Lines: "Note: Only the first 10 lines of this file will be used.",
      },
      prompt: "Upload data via .csv file",
    },
    om: {
      prompt: "Select .csv file containing OMs",
    },
  },
};

export default widgets;
