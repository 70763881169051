import type { PostCategoryId as CmsPostCategoryId, PostIds as CmsPostIds } from "./types";
import { postCategorySchema as cmsPostCategorySchema, postIdsSchema as cmsPostIdsSchema } from "./types";
import {
  getPost as getCmsPost,
  getPostBySlug as getCmsPostBySlug,
  getPostId as getCmsPostId,
  getPostsByCategory as getCmsPostsByCategory,
} from "./utils";

export { cmsPostCategorySchema, cmsPostIdsSchema, getCmsPost, getCmsPostBySlug, getCmsPostId, getCmsPostsByCategory };
export type { CmsPostCategoryId, CmsPostIds };
