import { Units } from "../../types/units";

// Complies with https://www.i18next.com/translation-function/plurals.
const buildUnitEntries = (key: string, singular: string, plural: string) => {
  return {
    [`${key}_one`]: singular,
    [`${key}_other`]: plural,
    [`${key}WithCount_one`]: `{{count}} ${singular}`,
    [`${key}WithCount_other`]: `{{count}} ${plural}`,
  };
};

const units: Units = {
  bytes: {
    ...buildUnitEntries("gigabyte", "GB", "GB"),
  },
  cpu: {
    ...buildUnitEntries("virtual", "vCPU", "vCPU"),
  },
};

export default units;
