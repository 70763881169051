import { defaultHaloRiskBandsConfig, defaultInputValues } from "@/features/risk_bands";
import portfolioExportTool from "@/tools/aggregate/portfolio-export";
import { defaultOfferingOfTools } from "@/tools/default_offering";
import { HubThemePartial } from "../../types";
import { HkmaHomeDashboard } from "./components/HkmaHomeDashboard";
import hkmaDatabase from "./tools/database";
// import hkmaRiskMap from "./tools/risk_map";

const THEME_COLOUR = {
  950: "#4B1553", // primary
  900: "#701a75",
  800: "#86198f",
  700: "#a21caf",
  600: "#c026d3",
  500: "#d946ef",
  400: "#e879f9",
  300: "#f0abfc",
  200: "#f5d0fe",
  100: "#f3e8ff",
  50: "#F7EEFA",
};

const supportEmail = "servicedesk@climaterisk.hk";

const hkmaThemeBase: HubThemePartial = {
  meta: {
    name: "",
    hasFontStyles: true,
    aesthetics: {
      attribution: "poweredByXdi",
      authorisation: {
        signUp: {
          warning: "Access to this platform is granted only with prior authorisation",
        },
      },
    },
    config: {
      featureSet: {
        comms: {
          feedback: {
            enabled: false,
          },
          emailSupport: {
            enabled: true,
            email: supportEmail,
          },
        },
        docs: {
          accountManagementTutorial: {
            enabled: false,
          },
          changeLog: {
            enabled: true,
          },
          contextualElementTutorials: {
            enabled: true,
          },
          knowledgeBase: {
            glossary: {
              enabled: true,
            },
            method: {
              enabled: true,
              publicDocsUrl: "",
            },
          },
          legal: {
            privacyPolicy: "https://climaterisk.hk/legal/privacy",
            termsOfService: "https://climaterisk.hk/legal/terms",
          },
          toolPageTutorials: {
            enabled: true,
          },
        },
        settings: {
          enabled: true,
        },
        tools: [...defaultOfferingOfTools, portfolioExportTool, hkmaDatabase],
        tracking: {
          hubspot: {
            enabled: true,
            allowEmbedCode: false,
            allowToolTracking: true,
            pipeline: {
              id: "84734851", // "HKMA"
              stage: "158543714", // "Open Stage (HKMA)"
            },
          },
        },
      },
      geocoding: {
        // Disabled because allows bypassing georestrictions.
        allowCoordinatesInQuickBatchScreen: false,
        coordinateRestrictions: {
          bounds: {
            type: "Polygon",
            // Rectangular bounds of HK. Using same source as Façades.
            // URL: https://www.landsd.gov.hk/en/resources/mapping-information/hk-geographic-data.html
            coordinates: [
              [
                [113.816666, 22.133333],
                [114.516667, 22.133333],
                [114.516667, 22.583334],
                [113.816666, 22.583334],
                [113.816666, 22.133333],
              ],
            ],
          },
          showBoundsOnMap: true,
        },
        defaultMapCentre: {
          lat: 22.3,
          lng: 114.171943,
        },
        defaultOrder: ["google"],
        locationRestrictions: {
          country: "HK",
        },
        minLengthForSuggestions: 2,
      },
      locale: {
        defaultCurrency: "HKD",
        // Residential defaults
        defaultInputValues: defaultInputValues.hkd.residential,
        defaultLanguage: "en-HK",
      },
      pages: {
        home: HkmaHomeDashboard,
      },
      riskBands: defaultHaloRiskBandsConfig,
      portfolioExport: {
        largeBank: {
          minAddresses: 30,
        },
        maxAddresses: 20000,
        template: {
          address: "8 Finance Street, Central",
          centre: {
            lat: 22.2855,
            lng: 114.1592,
          },
        },
      },
    },
    logoUrl: "/_/hkma-logo-centred-optimised.svg",
    logoIconUrl: "/_/hkma-logo-linear-optimised.svg",
  },
  antd: {
    token: {
      colorLink: THEME_COLOUR[800],
      colorLinkActive: THEME_COLOUR[600],
      colorLinkHover: THEME_COLOUR[600],
      colorPrimary: THEME_COLOUR[950],
      colorText: "#303030",
      controlItemBgActive: THEME_COLOUR[100],
      controlOutline: THEME_COLOUR[100],
      fontFamily:
        "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
      linkHoverDecoration: "underline",
    },
    components: {
      Button: {
        linkHoverDecoration: "underline",
        borderRadiusSM: 6,
        controlHeightSM: 28,
        paddingInlineSM: 10,
        borderRadius: 8,
      },
      Layout: {
        headerBg: "#f1f4f7",
      },
      Menu: {
        itemSelectedBg: THEME_COLOUR[50],
        itemSelectedColor: THEME_COLOUR[900],
      },
    },
  },
  translationOverrides: {
    "en-AU": {
      authentication: {
        captchaError: `Temporary error - please wait a moment and try again. If error persists, please contact ${supportEmail} and quote 'repeat captcha issue'.`,
      },
      tools: {
        aggregate: {
          remoteJobs: {
            job: "Portfolio",
            startNewRun: "Analyse New Portfolio",
          },
          portfolioExport: {
            description: {
              title: "What is Portfolio Export?",
              blurb:
                "The Portfolio Export tool allows you to run an analysis on a portfolio of up to 20,000 assets and provides a range of detailed metrics.  This tool provides whole-of-portfolio level insights so you can assess a backbook's forward-looking climate risk under a range of asset settings and emission scenarios. Unlike the Quick Batch Screen, you can customise archetype and design settings for individual assets within your portfolio. The system will return key aggregated results for the entire portfolio, as well as each asset's physical climate risk band and significant hazards at six key time intervals.",
            },
          },
        },
        riskBands: {
          single: {
            description: {
              title: "What is Single Asset Screen?",
              blurb:
                "The Single Asset Screen is a flexible tool for conducting a quick and simple assessment of a single existing address or site of a potential new development. Simply provide an address using the search capability or click anywhere on the map and select from a range of building archetypes using default settings. The Single Asset Screen will return high level climate risk results, including the asset's physical climate risk band over six key time intervals and significant hazards relevant to that location.",
            },
          },
          batch: {
            subtitle: "Assess physical risk of up to 30 addresses.",
            description: {
              title: "What is Quick Batch Screen?",
              blurb:
                "The Quick Batch Screen is used to assess physical risk to up to 30 addresses. Results returned include each asset's physical climate risk score over key time intervals and significant hazards, with results delivered on screen and as CSV export. For the most accurate results, please enter latitude/longitude co-ordinates.\nPlease note: Quick Batch Screen applies the same building settings to all addresses in the same batch.",
            },
          },
        },
        meta: {
          title: "Welcome To The Physical Risk Assessment Platform",
          subtitle:
            "This Platform is an on-demand tool for assessing physical climate risk to residential and commercial buildings in Hong Kong./nThe Platform has been developed by the Hong Kong Monetary Authority (HKMA) for use by Authorized Institutions (AIs) in Hong Kong. Technology and physical climate risk analysis is provided by XDI (Cross Dependency Initiative).",
        },
      },
      terms: {
        documents: {
          privacyPolicy: "Privacy Policy & PICS",
          termsOfService: "Terms of Use",
        },
        hazards: {
          cycloneWind: "Tropical Cyclone",
        },
      },
    },
  },
};

export default hkmaThemeBase;
