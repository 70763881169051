import { CmsPost } from "@/store/services/internal";

/**
 * Selects a single CMS post.
 * NOTE: Not a true selector. Got an infinite loop trying to load CMS posts in
 * this selector. Unsure how to proceed.
 */
export const selectCmsPost = (posts: CmsPost[], id: string): CmsPost | undefined => {
  return posts.find(({ slugSuffix: postId }) => id === postId);
};
