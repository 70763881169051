import { Pages } from "../../types/pages";

const pages: Pages = {
  assetLibrary: {
    title: "Data",
    addData: {
      title: "Add Data",
    },
  },
  login: {
    title: "Welcome back",
    subtitle: "Log in to your account",
    form: {
      email: "Email",
      password: "Password",
      button: "Log in",
    },
  },
  signUp: {
    title: "Get started",
    subtitle: "Create your account",
    form: {
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      passwordPrompt:
        "We recommend using a password manager to generate a random password that is unique, long, and complex.",
      button: "Sign up",
    },
  },
  forgotPassword: {
    title: "Reset password",
    subtitle:
      "Enter your email address and we'll send you a one-time passcode (OTP) to verify your password reset request.",
    form: {
      email: "Email",
      otp: "One-time passcode (OTP)",
      otpHelp: "Your OTP has been sent. Please check your email.",
      signInWithOtpError: "Something went wrong while sending your OTP. Please try again.",
      verifyOtpError: "Something went wrong while verifying your OTP. Please try again.",
    },
  },
  dashboards: {
    title: "Dashboards",
    drawer: {
      title: "Edit Dashboard",
      moduleDeleteConfirmation: "Are you sure you want to delete this module?",
    },
    errors: {
      notFound: "Cannot find dashboard",
    },
  },
  knowledgeBase: {
    title: "Knowledge Base",

    glossary: {
      title: "Glossary",
    },
    method: {
      title: "Methods & FAQ",
    },
  },
};

export default pages;
