import { JobMonitor as HacsJobMonitor } from "./classes/JobMonitor";
import { JobMonitorAggregator as HacsJobMonitorAggregator } from "./classes/JobMonitorAggregator";
import { OmDragger } from "./components/OmDragger";
import { SettingsForm } from "./components/SettingsForm";
import { templates } from "./constants/templates";
import { JobSchema, PollResult } from "./types/jobs";
import { OmUploadData } from "./types/om";
import type { Settings } from "./types/settings";
import { getMachineDetailsFromLineCount } from "./utils/ecs_task_options";
import { upload } from "./utils/upload";

// Classes
export { HacsJobMonitor, HacsJobMonitorAggregator };

// Components
export const HacsOmDragger = OmDragger;
export const HacsSettingsForm = SettingsForm;

// Constants
export const hacsTemplates = templates;

// Types
export type HacsJobSchema = JobSchema;
export type HacsOmUploadData = OmUploadData;
export type HacsPollResult = PollResult;
export type HacsSettings = Settings;

// Utils
export const getHacsMachineDetailsFromLineCount = getMachineDetailsFromLineCount;
export const uploadToHacs = upload;
