import { UnmatchedType } from "@/tools/risk-bands/batch";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Collapse, List } from "antd";
export interface UnmatchedAddressesCollapseProps {
  addresses: UnmatchedType[];
  onRetry?: () => void;
}
export const UnmatchedAddressesCollapse = ({
  addresses,
  onRetry
}: UnmatchedAddressesCollapseProps) => {
  const title = addresses.length === 1 ? "1 unmatched address" : `${addresses.length} unmatched addresses`;
  return <Collapse size="small" className="[&_.ant-collapse-header]:!items-center" items={[{
    key: "primary",
    label: `${title}. Click to view.`,
    extra: onRetry && <Button type="text" onClick={onRetry} icon={<ReloadOutlined />}>
              Retry
            </Button>,
    children: <List itemLayout="horizontal" dataSource={addresses} size="small" renderItem={(item, i) => <List.Item>
                  <List.Item.Meta title={`${i + 1}. ${item.address}`} description={item.error} />
                </List.Item>} />
  }]} data-sentry-element="Collapse" data-sentry-component="UnmatchedAddressesCollapse" data-sentry-source-file="UnmatchedAddressesCollapse.tsx"></Collapse>;
};