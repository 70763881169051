import { getConfig, getDocsConfig } from "@/features/branding";
import { store } from "@/store";
import { CmsPost, internalApi } from "@/store/services/internal";
import { selectCmsPost } from "@/store/services/internal/selectors";
import { PostCategoryId, PostIds } from "./types";

/**
 * Retrieves a post from given data.
 * @param category - Category of post
 * @param id - ID of post
 * @returns - Post or undefined
 */
export const getPost = async (category: PostCategoryId, id: string): Promise<CmsPost | undefined> => {
  const slug = getPostId(category, id);

  return slug ? getPostBySlug(slug) : undefined;
};

/**
 * Retrieves a post via slug.
 * @param slug - Post slug.
 * @returns - Post or undefined.
 */
export const getPostBySlug = async (slug: string): Promise<CmsPost | undefined> => {
  const getAllPostsAction = internalApi.endpoints.getCmsPosts.initiate();
  const { status, data } = await store.dispatch(getAllPostsAction);

  return status === "rejected" || !data ? undefined : selectCmsPost(data, slug);
};

/**
 * Finds the post ID for a given CMS post, if it exists.
 * @param category - Internal category (eg. "glossary").
 * @param id - Internal ID (eg. "rcps", "riskBands").
 * @returns - "Numerical" ID for HubSpot CMS Hub.
 */
export const getPostId = (category: PostCategoryId, id: string): string | undefined => {
  const { overrides } = getDocsConfig().contextualElementTutorials;

  // We either use the override or the default.
  const data = overrides[category]?.[id] || buildPostIds()[category][id];

  return typeof data === "function" ? data() : data;
};

/**
 * Returns all CMS posts within a given category.
 */
export const getPostsByCategory = async (category: PostCategoryId): Promise<CmsPost[] | undefined> => {
  const { overrides } = getDocsConfig().contextualElementTutorials;

  const getAllPostsAction = internalApi.endpoints.getCmsPosts.initiate();
  const { status, data } = await store.dispatch(getAllPostsAction);

  if (status === "rejected" || !data) {
    return undefined;
  }

  const categoryData = {
    ...buildPostIds()[category],
    ...(overrides[category] || []),
  };

  return Object.values(categoryData).reduce((list, value) => {
    const slug = typeof value === "function" ? value() : value;
    const post = selectCmsPost(data, slug);
    return post ? [...list, post] : list;
  }, [] as CmsPost[]);
};

/**
 * Builds a list of all post IDs.
 * @returns
 */
export const buildPostIds = (): PostIds => {
  /** The ID of every tool currently active in the system. */
  const allToolIds = getConfig().featureSet.tools.map((tool) => tool.id);

  const postIds: PostIds = {
    glossary: {
      archetype: "archetype",
      buildYear: "build-year",
      heightAboveGround: "height-above-ground",
      riskBands: () => `risk-bands-${getConfig().riskBands.mode === "halo" ? "abcde" : "abc"}`,
      significantHazards: "significant-hazards",
    },
    guide: {},
    method: {
      methodFaqs: "methods-and-faqs",
      // "temporarily" disabled while hkma review
      // geocodingExplanation: "geocoding-explanation",
      // ngfs: "ngfs",
    },
    test: {
      testPageRegular: "test-page-regular",
      testPageWide: "test-page-wide",
    },
  };

  if (process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("hkma")) {
    postIds.guide.enteringLocationsAndAddresses = "entering-locations-and-addresses-hk";
  }

  if (allToolIds.indexOf("portfolioAnalysis") !== -1) {
    postIds.glossary.portfolioAnalysisResultsExplanation = "what-do-my-portfolio-analysis-results-mean";
  }

  return postIds;
};
