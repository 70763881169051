import { convertCamelCaseToSentence } from "@/utils/string";
import classNames from "classnames";
import { AbcdeRiskValue } from "../schema";
import { ensureWordyRiskValue } from "../utils/conversion";
import styles from "./RiskBand.module.scss";
interface WordyRiskBandProps {
  rating: AbcdeRiskValue | "?";
  size: number;
  colour: string;
}
export const WordyRiskBand = ({
  rating,
  size,
  colour
}: WordyRiskBandProps) => {
  const innerMode = rating === "E" ? styles["inner--dark"] : styles["inner--light"];
  let text = rating === "?" ? "?" : convertCamelCaseToSentence(ensureWordyRiskValue(rating));
  let fontSize = size * 0.01;
  let innerClass = styles["inner--wordy-large"];
  if (size < 75) {
    innerClass = styles["inner--wordy-medium"];
  }
  if (size < 50) {
    fontSize *= 2;
    text = text.split(" ").map(segment => segment[0]).join("");
    innerClass = styles["inner--wordy-small"];
  }
  if (size < 25) {
    innerClass = styles["inner--wordy-tiny"];
  }
  return <div className={classNames(styles.outer, styles.outerWordy)} style={{
    background: colour,
    width: `${size}px`,
    height: `${size * 0.75}px`
  }} data-sentry-component="WordyRiskBand" data-sentry-source-file="WordyRiskBand.tsx">
      <div className={classNames(styles.inner, styles.innerWordy, innerClass, innerMode)}>
        {text.split(" ").map((text, key) => <div key={key} style={{
        display: "block",
        fontSize: `${fontSize}rem`
      }}>
            {text}
          </div>)}
      </div>
    </div>;
};