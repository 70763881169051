import { TextWithDescription } from "@/components/layouts/TextWithDescription";
import { Select, SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
interface Option {
  id: string;
  name: string;
  disabled?: boolean;
  description?: string;
  options?: Option[];
}
export interface SelectWithDescriptionsProps extends Omit<SelectProps, "options"> {
  options: Option[];
}

/**
 * Extends Ant Design's Select component to have descriptions for each option.
 */
export const SelectWithDescriptions = ({
  options: selectOptions,
  ...props
}: SelectWithDescriptionsProps) => {
  const structuredOptions = selectOptions.map(buildOption);
  return <Select options={structuredOptions} optionLabelProp="title" {...props} data-sentry-element="Select" data-sentry-component="SelectWithDescriptions" data-sentry-source-file="SelectWithDescriptions.tsx" />;
};

/**
 * Structure data so that the Select component can use it. Somewhat recursive.
 */
const buildOption = ({
  id,
  name,
  description,
  disabled,
  options
}: Option): DefaultOptionType => {
  const option: DefaultOptionType = {
    label: <TextWithDescription title={name} description={description} />,
    title: name,
    value: id,
    disabled
  };

  // AntD detects this property even if undefined and makes into group.
  // Therefore, unselectable.
  if (options) {
    option.options = options.map(buildOption);
  }
  return option;
};