import { buildYearListFromString } from "@/utils/years";
import { StructuralSettings } from "../../types/settings";

/**
 * db/Scenario.yaml
 */
type ScenarioYaml = StructuralSettings["files"]["db/Scenario.yaml"];

export const scenarioDefault: ScenarioYaml = {
  hazards: [
    "flood_riverine",
    "inundation",
    "heat",
    "forest_fire",
    "wind",
    "soil_movement",
    "freeze-thaw",
    "flood_surfacewater",
    // "cyclone_wind",
  ],
  years:
    "1990,1995,2000,2005,2010,2015,2020,2025,2030,2035,2040,2045,2050,2055,2060,2065,2070,2075,2080,2085,2090,2095,2100",
  organisation: "jba",
  waves: "off",

  profileFloodRiverine: "AUTO",
  profileHeat: "AUTO",
  profileInundation: "Haigh-et-al-2014",
  profileForestFire: "AUTO",
  profileSoilMovement: "AUTO",
  profileWind: "AUTO",
  profileFreezeThaw: "AUTO",
  profileFloodSurfaceWater: "AUTO",
  profileHeatStress: "AUTO",
  profileExcessMortality: "AUTO",
  profileLostHours: "AUTO",
  profileHeatWaveDays: "AUTO",
  heatStressLinearConst: 0.001,
  heatStressHourProb: 0.1,
  heatStressHourConst: 5.5,
  waveSetup: 15,
  assetDegradation: 0.0,
  yearOffset: 0,
  hazard_options: {
    inundation: {
      use_defended_areas: true,
      asset_lifting_RP: 0,
      use_wave: false,
    },
    flood_riverine: {
      use_defended_areas: true,
      asset_lifting_RP: 0,
    },
    flood_surfacewater: {
      use_defended_areas: true,
      asset_lifting_RP: 0,
    },
  },
};

export const scenarioAllYears: ScenarioYaml = {
  ...scenarioDefault,
  years: buildYearListFromString("1990-2100").join(","),
};

export const scenarioCyclone: ScenarioYaml = {
  ...scenarioDefault,
  hazards: [...scenarioDefault.hazards, "cyclone_wind"],
};
