import { downloadXlsxFile } from "@/features/custom_downloads";
import { PortfolioExportAssetLevelDownloadButton } from "@/features/pe_aldd";
import { RemoteJobActionsProps } from "@/features/remote_jobs";
import { logToolUsage } from "@/features/tracking";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { exportPortfolioSummary } from "@/tools/aggregate/portfolio-export/utils/stream";
import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { CloudDownloadOutlined, DeleteOutlined, EllipsisOutlined, StopOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Dropdown, notification } from "antd";
import { MenuProps } from "antd/lib";
import React, { useState } from "react";

/**
 *
 */
interface ActionButtonProps extends ButtonProps {
  isRaw?: boolean;
}
export type PortfolioExportJobActionsProps = Omit<RemoteJobActionsProps<PortfolioExportJobSchema>, "status" | "completedActions">;
export const PortfolioExportJobActions = (props: PortfolioExportJobActionsProps) => {
  const [notify, notificationContext] = notification.useNotification();
  const [isExporting, setIsExporting] = useState(false);
  const [key] = useKeyPair();
  const exportSummary = async () => {
    setIsExporting(true);
    if (!key.user) return;
    try {
      const buffer = await exportPortfolioSummary(props.schema.id, props.schema.summaryId, key.user.keyPair);
      if (buffer) {
        downloadXlsxFile(buffer, {
          filename: props.schema.name + "_summary.xlsx"
        });

        // Log to HubSpot when this has completed.
        logToolUsage({
          id: "aggregate.portfolioExport.summary",
          count: props.schema.assets.totalCount,
          portfolioId: props.schema.id,
          type: "portfolio_export:summary"
        });
      }
    } catch (err: any) {
      console.error("Error while exporting Portfolio Summary: ", err);
      notify.error({
        message: "Something went wrong",
        description: Array.isArray(err.errors) ? err.errors.join(" ") : "Please try again or contact your administrator"
      });
    }
    setIsExporting(false);
  };
  const buttons: (ActionButtonProps | undefined)[] = [];
  switch (props.schema.status) {
    case "completed_with_errors":
    case "completed":
      buttons.push({
        loading: isExporting,
        disabled: isExporting,
        icon: <CloudDownloadOutlined />,
        children: "Summary",
        onClick: exportSummary
      }, {
        children: <PortfolioExportAssetLevelDownloadButton schema={props.schema} />,
        isRaw: true
      });
      break;
    case "error":
      buttons.push({
        icon: <DeleteOutlined />,
        children: "Delete",
        onClick: () => props.onDeleteJob()
      });
      break;
    default:
      buttons.push({
        icon: <StopOutlined />,
        children: "Cancel",
        onClick: () => props.onDeleteJob()
      });
  }
  const dropdownMenuItems: MenuProps["items"] = [{
    key: "delete-portfolio",
    label: "Delete",
    onClick: props.onDeleteJob,
    icon: <DeleteOutlined />
  }];
  return <div style={{
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
    fontWeight: 500
  }} data-sentry-component="PortfolioExportJobActions" data-sentry-source-file="PortfolioExportJobActions.tsx">
      {notificationContext}
      {buttons.map((button, index) => {
      if (!button) {
        return undefined;
      }
      if (button.isRaw) {
        return <React.Fragment key={index}>{button.children}</React.Fragment>;
      }
      return <Button key={index} size="small" style={{
        fontWeight: "inherit"
      }} {...button} />;
    })}
      <Dropdown trigger={["click"]} disabled={props.schema.status !== "completed"} menu={{
      items: dropdownMenuItems
    }} overlayStyle={{
      width: 160
    }} data-sentry-element="Dropdown" data-sentry-source-file="PortfolioExportJobActions.tsx">
        <Button size="small" icon={<EllipsisOutlined rotate={90} />} style={{
        fontWeight: "inherit"
      }} type="text" data-sentry-element="Button" data-sentry-source-file="PortfolioExportJobActions.tsx" />
      </Dropdown>
    </div>;
};