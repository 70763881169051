import { Languages } from "../../types/languages";

const languages: Languages = {
  list: {
    "en-AU": "English (Australia)",
    "en-GB": "English (United Kingdom)",
    "en-HK": "English (Hong Kong)",
    "en-US": "English (United States)",
    "zh-CN": "Chinese (Simplified)",
    "zh-HK": "Chinese (Traditional)",
  },
};

export default languages;
