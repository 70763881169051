import { googleGeocoder } from "./google";
import { hongKongGovGeocoder } from "./hongKongGov";

const allGeocoders = {
  google: googleGeocoder,
  hkgov: hongKongGovGeocoder,
};

export type HubGeocoderKey = keyof typeof allGeocoders;
export default allGeocoders;
