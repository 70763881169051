import { ArchetypeConfig } from "../../schema";
import { defaultInputValues } from "../input_values";

const { hkd } = defaultInputValues;

/**
 * Archetypes specifically used for HKMA Hub and its Halo architecture.
 */
export const defaultHaloArchetypes: ArchetypeConfig[] = [
  {
    id: "high-vuln",
    name: "High-rise Residential Flat",
    value: "HighRiseFlat",
    category: "residential",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.residential,
  },
  {
    id: "med-vuln",
    name: "Low-rise Residential Flat",
    value: "LowRiseFlat",
    category: "residential",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.residential,
  },
  {
    id: "low-vuln",
    name: "Freestanding House",
    value: "FreestandingHouse",
    category: "residential",
    description: "",
    defaultHeightAboveGround: 0.5,
    defaultInputValues: hkd.residential,
  },
  {
    id: "std-house",
    name: "Shops/Shopping Malls",
    value: "Supermarket",
    category: "commercial",
    description: "",
    defaultHeightAboveGround: 0.19,
    defaultInputValues: hkd.commercial,
  },
  {
    id: "std-th",
    name: "Commercial Skyscraper",
    value: "HighRiseStructure",
    category: "commercial",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.commercial,
  },
  {
    id: "std-apt",
    name: "Commercial Building",
    value: "LowRiseStructure",
    category: "commercial",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.commercial,
  },
];
