import { Actions } from "../../types/actions";

const actions: Actions = {
  add: "Add",
  cancel: "Cancel",
  check: "Check",
  delete: "Delete",
  download: {
    default: "Download",
    csv: "Download .csv",
  },
  edit: "Edit",
  next: "Next",
  preview: "Preview",
  previous: "Previous",
  process: "Process",
  reset: "Reset",
  save: "Save",
  saveAll: "Save All",
};

export default actions;
