import { z } from "zod";

const scribeScrollModeSchema = z.enum(["scrolling", "stepped"]);

const airtablePropsSchema = z.object({
  type: z.literal("airtable"),
  slug: z.string().describe("Airtable identifier in structure of '<team_id>/<project_id>'."),
});

const hubspotFormPropsSchema = z.object({
  type: z.literal("hubspotForm"),
  formId: z.string(),
  id: z.string(),
  portalId: z.string().optional(),
  onBeforeFormSubmit: z.function().args(z.any(), z.any()).returns(z.void()).optional(),
});

const metabaseDashboardPropsSchema = z.object({
  type: z.literal("metabaseDashboard"),
  id: z.string(),
  /** Defaults to the-climate-risk-group. */
  namespace: z.string().optional(),
});

const scribePropsSchema = z.object({
  type: z.literal("scribe"),
  slug: z.string(),
  showToggle: z.boolean().optional(),
  mode: scribeScrollModeSchema.optional(),
});

const youtubePropsSchema = z.object({
  type: z.literal("youtube"),
  id: z.string(),
});

export const propsSchema = z.discriminatedUnion("type", [
  airtablePropsSchema,
  hubspotFormPropsSchema,
  metabaseDashboardPropsSchema,
  scribePropsSchema,
  youtubePropsSchema,
]);

export type Props = z.infer<typeof propsSchema>;
export type AirtableProps = z.infer<typeof airtablePropsSchema>;
export type HubspotFormProps = z.infer<typeof hubspotFormPropsSchema>;
export type MetabaseDashboardProps = z.infer<typeof metabaseDashboardPropsSchema>;
export type ScribeProps = z.infer<typeof scribePropsSchema>;
export type ScribeScrollMode = z.infer<typeof scribeScrollModeSchema>;
export type YouTubeProps = z.infer<typeof youtubePropsSchema>;
