import { ecsTaskCpuUnitsRamMatches } from "../constants/ecs_task_options";

interface MachineDetails {
  cpuUnits: number;
  ram: number;
}

const machineDetailsUnitMatcherOptions = [
  {
    cpuUnits: 2048,
    ramOptions: ecsTaskCpuUnitsRamMatches[2048],
  },
  {
    cpuUnits: 4096,
    ramOptions: ecsTaskCpuUnitsRamMatches[4096],
  },
  {
    cpuUnits: 8192,
    ramOptions: ecsTaskCpuUnitsRamMatches[8192],
  },
  {
    cpuUnits: 16384,
    ramOptions: ecsTaskCpuUnitsRamMatches[16384],
  },
];

/**
 * Determine which configuration we want to use on ECS based on line count.
 * @param lineCount - Number of lines we're processing.
 * @returns
 */
export const getMachineDetailsFromLineCount = (lineCount: number): MachineDetails => {
  // Minimum lines.
  if (lineCount < 8000) {
    return {
      cpuUnits: 2048,
      ram: 8192,
    };
  }

  // Bump number of lines up a bit to match RAM.
  lineCount *= 1.024;

  // Attempt to match options based on line count and RAM.
  for (const { cpuUnits, ramOptions } of machineDetailsUnitMatcherOptions) {
    for (const ram of ramOptions) {
      if (ram > lineCount) {
        return { cpuUnits, ram };
      }
    }
  }

  // Exceeds maximum lines.
  return {
    cpuUnits: 16384,
    ram: 122880,
  };
};
