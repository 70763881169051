import { ContainerOutlined, SwitcherOutlined } from "@ant-design/icons";
import { Segmented } from "antd";
import { useEffect, useState } from "react";
import type { ScribeProps, ScribeScrollMode } from "../../types";
import { IFrame } from "./components/IFrame";
import styles from "./Scribe.module.scss";
const LOCAL_STORAGE_KEY = "scribeScrollSetting";
export const Scribe = ({
  slug,
  mode = "stepped",
  showToggle = false
}: ScribeProps) => {
  const [toggleMode, setToggleMode] = useState<ScribeScrollMode>(showToggle ? localStorage && localStorage.getItem(LOCAL_STORAGE_KEY) as any || mode : mode);
  let url = `https://scribehow.com/embed/${slug}?skipIntro=true&removeLogo=true`;
  if (toggleMode === "scrolling") {
    url += "&as=scrollable";
  }
  useEffect(() => {
    if (localStorage) {
      localStorage.setItem(LOCAL_STORAGE_KEY, toggleMode);
    }
  }, [toggleMode]);
  return <>
      <IFrame src={url} data-sentry-element="IFrame" data-sentry-source-file="Scribe.tsx" />
      {showToggle && <Toggle onChange={setToggleMode} value={toggleMode} />}
    </>;
};
interface ToggleProps {
  onChange: (value: ScribeScrollMode) => void;
  value: ScribeScrollMode;
}
const Toggle = (props: ToggleProps) => {
  return <Segmented className={styles.toggle} options={[{
    icon: <ContainerOutlined />,
    value: "scrolling"
  }, {
    icon: <SwitcherOutlined />,
    value: "stepped"
  }]} onChange={value => props.onChange(value as ScribeScrollMode)} value={props.value} data-sentry-element="Segmented" data-sentry-component="Toggle" data-sentry-source-file="Scribe.tsx" />;
};