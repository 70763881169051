import { getKbConfig } from "@/features/branding";
import { Tool } from "@/types/tools";
import { BookOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { useEffect } from "react";
const buildSidebarRedirect = (): string => {
  return getKbConfig().method.publicDocsUrl || "/method/_";
};
const Render = () => {
  const router = useRouter();
  useEffect(() => {
    router.replace(buildSidebarRedirect());
  }, []);
  return <></>;
};
export default {
  id: "methods",
  category: "reference",
  keyPrefix: "reference.methods",
  icon: <BookOutlined />,
  render: () => <Render />,
  buildSidebarRedirect
} as Tool;