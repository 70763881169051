import { Resource } from "i18next";
import enAu from "./en-AU";
import enGb from "./en-GB";
import enHk from "./en-HK";
import enUs from "./en-US";

const resources: Resource = {
  "en-AU": enAu,
  "en-GB": enGb,
  "en-HK": enHk,
  "en-US": enUs,
};

// Apply theme-specific overrides.
export default Object.keys(resources).reduce((obj, key) => {
  const data: any = resources[key];

  return {
    ...obj,
    [key]: data,
  };
}, {});
