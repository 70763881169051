import { v4 } from "uuid";

/**
 * Generates IDs and caches them for a single extra use.
 *
 * This is to facilitate asynchronous generation of IDs for the same asset rows
 * without taking up excessive memory.
 */
export class PortfolioExportIdFactory {
  /** Stores generated index-string pairs for one-time access. */
  private cached = new Map<number, string>();
  /** Indexes which are currently being generated. */
  private lockedIndexes = new Set<number>();

  /**
   * Returns either a freshly generated UUID or the cached one.
   * @param index - Row of the .csv file we'd like to get a UUID for.
   * @returns The UUID generated for the given index.
   */
  public async get(index: number): Promise<string> {
    // Wait if the ID is being generated.
    while (this.lockedIndexes.has(index)) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    // If the value is already cached, delete from cache and return it.
    if (this.cached.has(index)) {
      const value = this.cached.get(index)!;
      this.cached.delete(index);
      return value;
    }

    // Claim the process for generating the UUID.
    this.lockedIndexes.add(index);

    // Generate the UUID and once it's done, add to cache and delete lock.
    const value = v4();
    this.cached.set(index, value);
    this.lockedIndexes.delete(index);

    return value;
  }
}
