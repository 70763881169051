import { formatStandardDateTime } from "@/utils/datetime";
import type { Options } from "./types";

const buildLink = (parts: BlobPart[], mimeType = "text/plain") => {
  const blob = new Blob(parts, {
    type: mimeType,
  });
  return URL.createObjectURL(blob);
};

export const download = (content: BlobPart, options: Options, mimeType = "text/plain") => {
  let filename = options.filename;
  if (options.prefixDate) {
    filename = `${formatStandardDateTime(new Date())}_${filename}`;
  }

  const a = document.createElement("a");

  let link = content;
  if (!options.preventProcessing) {
    if (options.includeBom) {
      // BOM sourced from "Byte order marks by encoding" table from below link.
      // URL: https://en.wikipedia.org/wiki/Byte_order_mark
      link = buildLink([new Uint8Array([0xef, 0xbb, 0xbf]), content], mimeType + ";charset=utf-8");
    } else {
      link = buildLink([content], mimeType);
    }
  }

  a.setAttribute("href", link as any);
  a.setAttribute("download", filename);
  a.click();
};
