import { merge } from "lodash/fp";
import { Translation } from "../../types";
import enAu from "../en-AU";

/**
 * English (HK) translations - extends English (AU).
 */
const enHk: Translation = merge(enAu, {} as Partial<Translation>);

export default enHk;
