import { Button, Modal, ModalProps } from "antd";
import { useTranslation } from "react-i18next";
import { JobSchema } from "../types/JobSchema";
export interface NewRunModalProps<T extends JobSchema> extends ModalProps {
  onRunStart: (schema: T) => void;
}
export const NewRunModal = <T extends JobSchema,>(props: NewRunModalProps<T>) => {
  const {
    t
  } = useTranslation("tools", {
    keyPrefix: "aggregate.remoteJobs"
  });
  const onUpload = async () => {
    const schema: JobSchema = {
      name: "New Run",
      startTime: new Date().getTime()
    };

    // TODO: Go through the system and remove this.
    if (props.onRunStart) {
      props.onRunStart(schema as T);
    }
  };
  return <Modal destroyOnClose={true} title={t("startNewRun")} {...props} data-sentry-element="Modal" data-sentry-component="NewRunModal" data-sentry-source-file="NewRunModal.tsx">
      {props.children || <Button onClick={onUpload}>Replace Me</Button>}
    </Modal>;
};