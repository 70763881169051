import { getKbConfig, getRiskBandsMode } from "@/features/branding";
import { InteractiveTutorial, InteractiveTutorialProps } from "@/features/interactive_tutorial";
import { RiskBandsBatchStep1Refs } from "@/tools/risk-bands/components/BatchStep1";
import { RiskBandsBatchStep3Refs } from "@/tools/risk-bands/components/BatchStep3";
import { GeocodeWithResult } from "@/tools/risk-bands/components/MultipleResultsTable";
import { tourSingleResponseAbc, tourSingleResponseHalo } from "@/tools/risk-bands/tutorials/data/single";
import { Alert, TourStepProps } from "antd";
import Link from "next/link";
import { MutableRefObject, useEffect } from "react";
export interface QuickBatchScreenState {
  addresses: string;
  results: GeocodeWithResult[];
}
export interface QuickBatchScreenTutorial extends InteractiveTutorialProps<QuickBatchScreenState> {
  refs: {
    newQuickBatch?: MutableRefObject<null>;
    step1?: RiskBandsBatchStep1Refs;
    step3?: RiskBandsBatchStep3Refs;
  };
}
export const QuickBatchScreenTutorial = ({
  refs,
  ...props
}: QuickBatchScreenTutorial) => {
  // Dummy data used for displaying the tour.
  const tourResponse = {
    abc: tourSingleResponseAbc,
    halo: tourSingleResponseHalo,
    simple: tourSingleResponseHalo
  }[getRiskBandsMode()];
  const tutorialState: QuickBatchScreenState = {
    addresses: "Disneyland Hong Kong, Lantau Island, Hong Kong",
    results: [{
      geocode: {
        confidence: 100,
        input: "Disneyland Hong Kong, Lantau Island, Hong Kong",
        latLng: {
          lat: 22.3129666,
          lng: 114.0412819
        },
        name: "",
        quality: "Good",
        source: "google"
      },
      result: tourResponse
    }]
  };
  useEffect(() => {
    if (props.open) {
      props.setState(tutorialState);
    }
  }, [props.open]);
  const steps: TourStepProps[] = [{
    title: "",
    description: <>
          <p>
            Under &apos;<b>Enter Addresses</b>&apos;, click the &apos;<b>Paste a list of assets here (maximum 30)</b>
            &apos; field and add up to 30 address locations, each on a single line.
          </p>
          <p>
            Please enter addresses in the following format where possible:
            <br />
            <i>Street number, Street Name, Village/Town/District, Region</i>
          </p>
          <Alert description="Do not enter unit numbers, suite numbers or floor numbers." showIcon type="error" />
        </>,
    placement: "center",
    target: () => refs.step1?.addresses.current || null
  }, {
    title: "Under 'Settings', choose an asset type from the 'Asset Type (Archetype)' selection drop-down menu.",
    description: getKbConfig().glossary.enabled && <>
          <p>
            For more information on selecting archetypes, see{" "}
            <Link href="/glossary/archetype" target="_blank">
              Glossary – Archetype
            </Link>
            .
          </p>
          <p>
            <Alert description="Quick Batch Screen applies the same Asset Type (Archetype), Build Year, and Height Above Ground to ALL supplied addresses." showIcon type="info" />
          </p>
        </>,
    target: () => refs.step1?.advancedSettings?.archetype.current || null
  }, {
    title: "Enter asset build year.",
    description: <>
          <p>
            If unsure of the year the asset was built, leave default <b>Build Year</b> value in the field, or enter
            exact/estimated <b>Build Year</b> if known.
          </p>
        </>,
    target: () => refs.step1?.advancedSettings?.buildYear.current || null
  }, {
    title: "Enter the asset's 'Height Above Ground'.",
    description: <>
          <p>
            You can estimate this by selecting a foundation type from the drop-down menu, or choose &apos;<b>Custom</b>
            &apos; to enter a precise height value (metres).
          </p>
        </>,
    target: () => refs.step1?.advancedSettings?.heightAboveGround.current || null
  }, {
    title: "",
    description: <>
          Click &apos;<b>Process</b>&apos; to begin the quick batch screen and view the assets&apos; Risk Rating.
        </>,
    target: () => refs.step1?.process.current || null
  }, {
    title: "Assets will populate onto the map.",
    description: "Click an asset to see its Risk Rating."
  }];
  if (getRiskBandsMode() !== "abc") {
    steps.push({
      title: "",
      description: "Use the drop-down menu on the bottom right to compare between four different RCP scenarios – 2.6, 4.5, 6.0 or 8.5.",
      target: () => refs.step3?.rcp?.current || null
    });
  }
  steps.push(...[{
    title: "",
    description: <>
            To toggle between list and map view of all assets and risk ratings, click on &apos;<b>List</b>&apos; or
            &apos;<b>Map</b>&apos; button on top left of the map window.
          </>,
    target: () => refs.step3?.tabToggler?.current || null
  }, {
    title: "",
    description: <>
            Click &apos;<b>Download .csv</b>&apos; to download data as a .csv file.
          </>,
    target: () => refs.step3?.downloadCsv?.current || null
  }, {
    title: "",
    description: <>
            To exit results and screen a different address, click &apos;<b>New Quick Batch</b>&apos;.
          </>,
    target: () => refs.newQuickBatch?.current || null
  }]);
  return <InteractiveTutorial<QuickBatchScreenState> steps={steps} {...props} data-sentry-element="InteractiveTutorial" data-sentry-component="QuickBatchScreenTutorial" data-sentry-source-file="batch.tsx" />;
};