import type { InputValuesConfig } from "../schema";

interface InputValuesGroup {
  residential: InputValuesConfig;
  commercial: InputValuesConfig;
}

interface InputValues {
  aud: InputValuesGroup;
  hkd: InputValuesGroup;
}

export const defaultInputValues: InputValues = {
  aud: {
    commercial: {
      replacementCost: 1000000,
      marketValue: 1400000,
      mortgageRate: 0.05,
    },
    residential: {
      replacementCost: 350000,
      marketValue: 500000,
      mortgageRate: 0.05,
    },
  },
  hkd: {
    commercial: {
      replacementCost: 5000000,
      marketValue: 7000000,
      // NON-STANDARD: This is specifically for HKMA. Change system up when implementing currency.
      mortgageRate: 0.035,
    },
    residential: {
      replacementCost: 1750000,
      marketValue: 2500000,
      // NON-STANDARD: This is specifically for HKMA. Change system up when implementing currency.
      mortgageRate: 0.035,
    },
  },
};
