import { Form, FormItemProps } from "antd";
export interface HubFormItemProps extends FormItemProps {
  error?: any | any[];
}
export const HubFormItem = ({
  error,
  ...props
}: HubFormItemProps) => {
  if (Array.isArray(error)) {
    error = error[0];
  }
  return <Form.Item help={error?.message || ""} validateStatus={error ? "error" : ""} {...props} data-sentry-element="unknown" data-sentry-component="HubFormItem" data-sentry-source-file="HubFormItem.tsx" />;
};