/**
 * This is the standard public exponent. It is not recommended to change this.
 * Source: https://crypto.stackexchange.com/questions/3110/impacts-of-not-using-rsa-exponent-of-65537
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/RsaHashedKeyGenParams
 */
export const RSA_PUBLIC_EXPONENT = new Uint8Array([1, 0, 1]);

export const KEY_PAIR_ALGORITHM: RsaHashedKeyAlgorithm = {
  name: "RSA-OAEP",
  modulusLength: 4096,
  publicExponent: RSA_PUBLIC_EXPONENT,
  hash: {
    name: "SHA-256",
  },
};
