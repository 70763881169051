import { Row, Typography } from "antd";
const {
  Text
} = Typography;
interface TextWithDescriptionProps {
  title: string;
  description?: string;
}
export const TextWithDescription = ({
  title,
  description
}: TextWithDescriptionProps) => {
  return <>
      <Row data-sentry-element="Row" data-sentry-source-file="TextWithDescription.tsx">{title}</Row>
      {description && <Row>
          <Text type="secondary" style={{
        whiteSpace: "normal",
        fontSize: "90%"
      }}>
            {description}
          </Text>
        </Row>}
    </>;
};