import { getConfig } from "@/features/branding";
import allGeocoders, { HubGeocoderKey } from "./geocoders";
import { HubGeocoderResult } from "./types";

/**
 * Attempts to turn an address into a HubGeocoderResult, using a list of geocoders.
 * @param address - The address to look up.
 * @param geocoders - Keys of geocoders to try, in order.
 * @param threshold - Confidence threshold which constitutes an immediate match.
 * @returns The HubGeocoderResult, or undefined.
 */
export const geocodeAddress = async (
  address: string,
  geocoders: HubGeocoderKey[],
  threshold = getConfig().geocoding.confidenceThreshold
): Promise<HubGeocoderResult | undefined> => {
  let best: HubGeocoderResult | undefined = undefined;

  for (const key of geocoders) {
    const geocoder = allGeocoders[key];

    // Geocoders which don't accept address lookup need not apply.
    if (!geocoder.stringToResult) {
      continue;
    }

    const result = await geocoder.stringToResult(address);

    if (!result) {
      continue;
    }

    // If we can be confident in the result, just return it.
    if (result.confidence >= threshold) {
      return result;
    }

    // Replace if we are more confident in this result than the previous best.
    if (!best || result.confidence > best.confidence) {
      best = result;
    }
  }

  return best;
};
