// NOTE: Google doesn't return a confidence value, so this is an approximation.
// A more accurate estimation would factor in the size of the result's bounding box.
const getConfidenceFromLocationType = (locationType: string): number => {
  switch (locationType) {
    case "ROOFTOP":
      return 100;

    case "RANGE_INTERPOLATED":
      return 70;

    case "GEOMETRIC_CENTER":
      return 60;

    case "APPROXIMATE":
      return 40;

    default:
      return 0;
  }
};

export const buildResultFromGoogleGeocoderResponse = ({ results }: google.maps.GeocoderResponse) => {
  if (!results.length) {
    return undefined;
  }

  const result = results[0];
  return {
    name: result.formatted_address,
    latLng: result.geometry.location.toJSON(),
    confidence: getConfidenceFromLocationType(result.geometry.location_type),
    source: "google",
  };
};
