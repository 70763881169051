import { ProcessStatus } from "@/types/processes";
import { CheckCircleFilled, CloseSquareFilled, CloudUploadOutlined, SyncOutlined, WarningFilled } from "@ant-design/icons";
import { Tag, TagProps } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./ProcessStatus.module.scss";
export interface ProcessStatusIconProps extends TagProps {
  /** Which stage the process is in. */
  status: ProcessStatus;
}
type ProcessIcons = { [K in ProcessStatus]: JSX.Element };
const icons: ProcessIcons = {
  failed: <CloseSquareFilled style={{
    color: "#dc2626"
  }} />,
  error: <CloseSquareFilled style={{
    color: "#dc2626"
  }} />,
  uploading: <CloudUploadOutlined style={{
    color: "#f97316"
  }} className={styles.easeUp} />,
  warning: <WarningFilled style={{
    color: "#eab308"
  }} />,
  completed: <CheckCircleFilled style={{
    color: "#16a34a"
  }} />,
  success: <CheckCircleFilled style={{
    color: "#16a34a"
  }} />,
  completed_with_errors: <WarningFilled style={{
    color: "#eab308"
  }} />,
  processing: <SyncOutlined spin style={{
    color: "#0958d9"
  }} />,
  pending: <SyncOutlined spin style={{
    color: "#0958d9"
  }} />,
  provisioning: <SyncOutlined spin style={{
    color: "#0958d9"
  }} />,
  running: <SyncOutlined spin style={{
    color: "#0958d9"
  }} />,
  unknown: <SyncOutlined spin style={{
    color: "black"
  }} />
};

/**
 * Displays the icon of an ongoing or completed process.
 */
export const ProcessStatusIcon = ({
  status
}: ProcessStatusIconProps): JSX.Element => {
  return <>{icons[status]}</>;
};
export interface ProcessStatusTagProps extends TagProps {
  /** Which stage the process is in. */
  status: ProcessStatus;
  customText?: string;
}

/**
 * Displays the status of an ongoing or completed process.
 */
export const ProcessStatusTag = ({
  customText,
  status,
  ...props
}: ProcessStatusTagProps) => {
  const {
    t
  } = useTranslation("terms", {
    keyPrefix: "status"
  });
  const buildTag = (status: ProcessStatus, color?: string) => {
    return <Tag icon={icons[status]} color={color} {...props} data-sentry-element="Tag" data-sentry-component="buildTag" data-sentry-source-file="ProcessStatusTag.tsx">
        {customText || t(status).toLowerCase()}
      </Tag>;
  };
  switch (status) {
    case "failed":
    case "error":
      return buildTag("error", "red");
    case "processing":
    case "running":
      return buildTag("processing", "blue");
    case "uploading":
      return buildTag("processing", "orange");
    case "warning":
      return buildTag("warning", "gold");
    case "completed":
    case "success":
      return buildTag("success", "green");
    default:
      return buildTag(status, "default");
  }
};