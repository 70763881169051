import { HubGeocoder } from "../types";
import { HongKongGovResponse } from "./types";
import { buildResultFromHongKongAddress } from "./utils";

const hkLookup = async (
  address: string,
  options: {
    isBasicSearch: boolean;
    maxCount: number;
  }
): Promise<HongKongGovResponse> => {
  let url = `https://www.als.ogcio.gov.hk/lookup?q=${encodeURIComponent(address)}`;

  // Basic search looks to be as verbose as a regular search, maybe more?
  // I don't understand it, but if someone figures it out, here's an option for it.
  if (options.isBasicSearch) {
    url += "@b=1";
  }

  if (options.maxCount) {
    url += `&n=${options.maxCount}`;
  }

  const data = await fetch(url, {
    headers: {
      Accept: "application/json",
    },
  });
  return await data.json();
};

export const hongKongGovGeocoder: HubGeocoder = {
  name: "Hong Kong Government - Address Lookup Service",
  reference: "https://data.gov.hk/en-data/dataset/hk-ogcio-st_div_02-als/resource/af49e110-6765-4c80-ae07-968066da3614",
  stringToResult: async (address) => {
    const response = await hkLookup(address, {
      isBasicSearch: false,
      maxCount: 1,
    });
    return buildResultFromHongKongAddress(response.SuggestedAddress[0], address);
  },
  autocompleter: {
    getSuggestions: async (address) => {
      const response = await hkLookup(address, {
        isBasicSearch: true,
        maxCount: 5,
      });
      return response.SuggestedAddress.reduce<any[]>((list, addr) => {
        const result = buildResultFromHongKongAddress(addr, address);

        // Filter out duplicate addresses.
        if (list.find(({ value }) => value === result.name)) {
          return list;
        }

        return [
          ...list,
          {
            data: result,
            value: result.name,
          },
        ];
      }, []);
    },
    suggestionToResult: async ({ data }) => {
      return new Promise((res) => res(data));
    },
  },
};
