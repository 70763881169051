import { Loading } from "@/components/overlays/Loading";
import { useGetAssuranceLevelQuery } from "@/store/services/supabase";
import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import { useEffect } from "react";
export const AuthCheck = ({
  children
}: {
  children: JSX.Element;
}) => {
  const router = useRouter();
  const session = useSession();
  const supabase = useSupabaseClient();
  const {
    data: assuranceLevel,
    refetch: refetchAssuranceLevel
  } = useGetAssuranceLevelQuery();
  useEffect(() => {
    refetchAssuranceLevel().then(({
      data
    }) => {
      if (!data || !data.hasVerifiedMFA) {
        router.push("/login");
      }
    });
  }, []);
  useEffect(() => {
    if (!session) {
      router.push("/login");
    }
    const {
      data: authListener
    } = supabase.auth.onAuthStateChange(event => {
      console.log(`Supabase auth event: ${event}`);
      if (event === "SIGNED_OUT") {
        sessionStorage.clear();
      }
    });
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [session]);
  if (!session || !assuranceLevel || !assuranceLevel.hasVerifiedMFA) {
    return <Loading />;
  }
  return children;
};