/**
 * Handles all forms of tracking.
 */

import { z } from "zod";
import { Form as HubspotForm, FormProps as HubspotFormProps, sendDataToForm as sendDataToHubspotForm } from "./apis/hubspot";
import { schema as hubspotTrackingConfigSchema } from "./apis/hubspot/types";
import { HubPlausibleProvider } from "./apis/plausible";
import logToolUsage from "./functions/log_tool_usage";
import { Config as TrackingConfig, configSchema as trackingConfigSchema } from "./types";

// Components
export { HubspotForm, HubPlausibleProvider };
// Functions
export { logToolUsage, sendDataToHubspotForm };
// Schemas
export { trackingConfigSchema, hubspotTrackingConfigSchema };
export type { HubspotFormProps, TrackingConfig };

// Types
export type HubspotTrackingConfig = z.infer<typeof hubspotTrackingConfigSchema>;