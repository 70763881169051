import { SteppedTimeline } from "@/components/status/SteppedTimeline";
import { getConfig } from "@/features/branding";
import { isPointWithinSystemBounds } from "@/features/geographic_data";
import { getRiskBands } from "@/features/risk_bands";
import { RiskBandsRequest } from "@/store/services/facades/types";
import { UnmatchedType } from "@/tools/risk-bands/batch";
import { GeocodeWithResult } from "@/tools/risk-bands/components/MultipleResultsTable";
import { RiskBandsAdvancedSettingsValues } from "@/tools/risk-bands/types";
import { convertStringToLatLng } from "@/utils/geocoding";
import { Col, notification } from "antd";
import { isEqual, startCase, toLower } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export interface RiskBandsBatchStep2Props {
  addresses: string[];
  advancedSettings: RiskBandsAdvancedSettingsValues;
  prevAdvancedSettings: RiskBandsAdvancedSettingsValues | null;
  prevOutput: GeocodeWithResult[] | undefined;
  prevUnmatchedAddresses: UnmatchedType[];
  onError: () => void;
  onProgress: (results: GeocodeWithResult[], unmatched: UnmatchedType[]) => void;
}
export const RiskBandsBatchStep2 = (props: RiskBandsBatchStep2Props) => {
  const {
    allowCoordinatesInQuickBatchScreen
  } = getConfig().geocoding;
  const {
    maxAddresses
  } = getConfig().riskBands.quickBatch;
  const unmatched: UnmatchedType[] = [];
  const [step, setStep] = useState(0);
  const {
    t
  } = useTranslation("tools", {
    keyPrefix: "riskBands.batch"
  });
  const prevOutput = props.prevOutput ?? [];
  const prevAddresses = prevOutput.map(item => item.geocode.input);
  const prevUnmatchedAddresses = props.prevUnmatchedAddresses ? props.prevUnmatchedAddresses.map(item => item.address) : [];
  const getUnprocessedAddresses = () => {
    if (isEqual(props.advancedSettings, props.prevAdvancedSettings)) {
      return props.addresses.filter(address => !prevAddresses.includes(address)).filter(address => !prevUnmatchedAddresses.includes(address));
    }
    return props.addresses;
  };
  const getMergedOutputs = (results: any, unmatched: UnmatchedType[]) => {
    if (isEqual(props.advancedSettings, props.prevAdvancedSettings)) {
      // Only get the previously processed addresses included in the new address list
      const filteredPrev = prevAddresses.filter(a => props.addresses.includes(a));
      const filteredPrevUnmatched = prevUnmatchedAddresses.filter(a => props.addresses.includes(a));

      // Merge it with the new outputs
      const updatedResults = prevOutput.length ? [...results, ...prevOutput.filter(item => filteredPrev.includes(item.geocode.input))] : results;
      const updatedUnmatched = props.prevUnmatchedAddresses.length ? [...unmatched, ...props.prevUnmatchedAddresses.filter(a => filteredPrevUnmatched.includes(a.address))] : unmatched;
      return {
        results: updatedResults,
        unmatched: updatedUnmatched
      };
    }
    return {
      results,
      unmatched
    };
  };
  const runProcesses = async () => {
    const unprocessedAddresses = getUnprocessedAddresses();
    if (props.addresses.length > maxAddresses) {
      notification.error({
        message: "Too many addresses submitted to Quick Batch. Please contact your system administrator."
      });
      props.onError();
      return;
    }
    setStep(1);

    // Build risk bands for all addresses.
    const results = await Promise.all(unprocessedAddresses.map(async (address: string) => {
      // Try to convert string to LatLng, only if allowed to.
      const latLng = allowCoordinatesInQuickBatchScreen ? convertStringToLatLng(address) : null;

      // Ensure point is not outside of restrictions.
      if (latLng && !isPointWithinSystemBounds(latLng)) {
        unmatched.push({
          status: 400,
          address,
          error: "Outside of geographic limitations."
        });
        return {
          status: "failure"
        };
      }
      const additionalData = latLng ? {
        coordinates: {
          latitude: latLng.lat,
          longitude: latLng.lng
        }
      } : {
        geocoding: {
          address
        }
      };
      const requestData = {
        ...additionalData,
        archetype: props.advancedSettings?.archetype,
        build_year: props.advancedSettings?.buildYear,
        thresholds: {
          flood: {
            height_above_ground: props.advancedSettings?.heightAboveGround?.amount || 0
          }
        }
      } as RiskBandsRequest;
      const promise = getRiskBands(requestData);
      return promise.then(response => {
        if (response.status === "rejected") {
          if ("message" in response.error) {
            const rawStatus = "status" in response.error ? response.error.status : undefined;
            const status = typeof rawStatus === "number" ? rawStatus : 500;
            unmatched.push({
              status,
              address,
              error: `${response.error.message}`
            });
          }
          return {
            status: "failure"
          };
        }
        return {
          status: "success",
          geocode: {
            input: address,
            name: "",
            // Add matched address from geocoder when available.
            source: response.data?.metadata.geocoding?.source,
            latLng: {
              lat: response.data?.metadata.location?.latitude,
              lng: response.data?.metadata.location?.longitude
            },
            quality: startCase(toLower(response.data?.metadata.geocoding?.quality))
          },
          result: response.data
        };
      });
    })).then(res => res.filter(res => res.status !== "failure"));
    if (results.some(({
      status
    }) => status === "failure")) {
      // TODO: Retry failures once.
      props.onError();
      notification.error({
        message: "Error when contacting results server. Please try again."
      });
    }
    setStep(2);
    const outputs = getMergedOutputs(results, unmatched);
    props.onProgress(outputs.results, outputs.unmatched);
  };
  useEffect(() => {
    runProcesses();
  }, []);
  return <>
      <Col style={{
      width: "100%",
      paddingBlockStart: "5em"
    }} data-sentry-element="Col" data-sentry-source-file="BatchStep2.tsx">
        <SteppedTimeline steps={t("step2Steps", {
        returnObjects: true
      })} current={step} data-sentry-element="SteppedTimeline" data-sentry-source-file="BatchStep2.tsx" />
      </Col>
    </>;
};