import { DownloadButton } from "@/components/buttons/DownloadButton";
import { getArchetypes, getConfig } from "@/features/branding";
import { downloadCsvFile } from "@/features/custom_downloads";
import { HacsOmDragger, HacsOmUploadData } from "@/features/hacs";
import { useGetOrgQuery } from "@/store/services/supabase";
import { buildPortfolioExportExampleCsv } from "@/tools/aggregate/portfolio-export/constants";
import { Col, Row, Typography } from "antd";
const {
  Title
} = Typography;
export interface PortfolioExportUploadFormProps {
  /** Handles whenever a CSV is selected. */
  setData: (data?: HacsOmUploadData) => void;
  /** File data for the chosen CSV upload. */
  data?: HacsOmUploadData;
}

/**
 * Form used to upload the source file for a Portfolio Export run.
 */
export const PortfolioExportUploadForm = ({
  setData,
  data
}: PortfolioExportUploadFormProps) => {
  return <>
      <HacsOmDragger data={data} onDataChange={setData} prompt="Select .csv file" data-sentry-element="HacsOmDragger" data-sentry-source-file="PortfolioExportUploadForm.tsx" />
      <InputSpecification data-sentry-element="InputSpecification" data-sentry-source-file="PortfolioExportUploadForm.tsx" />
    </>;
};

/**
 * Explains how the input file should be structured and provides an example.
 */
const InputSpecification = () => {
  const archetypes = getArchetypes();
  const {
    data: org
  } = useGetOrgQuery();
  const {
    portfolioExport: config
  } = getConfig();
  const minAddresses = config.largeBank.minAddresses.toLocaleString();
  const maxAddresses = config.maxAddresses.toLocaleString();
  return <>
      <Title level={5} data-sentry-element="Title" data-sentry-source-file="PortfolioExportUploadForm.tsx">Input Specification</Title>
      <Row className="prose" data-sentry-element="Row" data-sentry-source-file="PortfolioExportUploadForm.tsx">
        <Col span={24} style={{
        marginBottom: "1rem"
      }} data-sentry-element="Col" data-sentry-source-file="PortfolioExportUploadForm.tsx">
          <p style={{
          marginTop: 0
        }}>
            This tool accepts a .csv file with a specific structure. Please refer to the example file below for more
            details.
          </p>
          <p style={{
          marginBottom: 0
        }}>Other rules that must be followed include:</p>
          <ul style={{
          marginTop: 0
        }}>
            <li>IDs must be unique.</li>
            <li>Headers must exactly match the ones supplied in the example file.</li>
            <li>Columns outside of the ones specified in the example file are invalid.</li>

            {org?.is_large_bank && minAddresses ? <li>
                Number of assets must be between {minAddresses} and {maxAddresses}.
              </li> : <li>Number of assets cannot exceed {maxAddresses}.</li>}
            <li>
              Only the following archetypes can be used:
              <ul>
                {archetypes.map(a => <li key={a.id}>{a.name}</li>)}
              </ul>
            </li>
          </ul>
        </Col>
        <Col span={24} data-sentry-element="Col" data-sentry-source-file="PortfolioExportUploadForm.tsx">
          <DownloadButton onClick={downloadExample} data-sentry-element="DownloadButton" data-sentry-source-file="PortfolioExportUploadForm.tsx">Download Template .csv</DownloadButton>
        </Col>
      </Row>
    </>;
};

/**
 * Causes the browser to download the example template.
 */
const downloadExample = () => {
  downloadCsvFile(buildPortfolioExportExampleCsv(), {
    filename: "Portfolio Upload Template.csv"
  });
};