import { KEY_PAIR_STORE } from "@/utils/hooks/useKeyPair/constants";
import { KeyPair, KeyPairWithSalt, Salt } from "@/utils/hooks/useKeyPair/types";
import { getKeysFromSessionStorage } from "@/utils/hooks/useKeyPair/utils";
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from "react";
export type KeyPairContextType = {
  user: {
    keyPair: KeyPair["keyPair"];
    salt: Salt["salt"];
  } | undefined;
  group?: {
    keyPair: KeyPair["keyPair"];
    salt: Salt["salt"];
  } | undefined;
  isLoading: boolean;
  refetch: any;
};
export const KeyPairContext = createContext<KeyPairContextType>({
  user: undefined,
  group: undefined,
  isLoading: true,
  refetch: null
});
export const KeyPairProvider = ({
  children
}: PropsWithChildren) => {
  const [userKeyPair, setUserKeyPair] = useState<KeyPairWithSalt>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let mounted = true;
    async function getKeyPair() {
      setIsLoading(true);
      if (mounted) {
        setUserKeyPair(await getKeysFromSessionStorage(KEY_PAIR_STORE.user));
        setIsLoading(false);
      }
    }
    getKeyPair();
    return () => {
      mounted = false;
    };
  }, []);
  const refetch = async () => {
    console.log("refetch");
    setUserKeyPair(await getKeysFromSessionStorage(KEY_PAIR_STORE.user));
  };
  const value = useMemo(() => {
    if (isLoading) {
      return {
        user: undefined,
        isLoading: true,
        refetch
      };
    }
    if (!userKeyPair) {
      return {
        user: undefined,
        isLoading: false,
        refetch
      };
    }
    return {
      user: {
        keyPair: userKeyPair.keyPair,
        salt: userKeyPair.salt
      },
      isLoading: false,
      refetch
    };
  }, [userKeyPair]);
  return <KeyPairContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="KeyPairProvider" data-sentry-source-file="KeyPairProvider.tsx">{children}</KeyPairContext.Provider>;
};