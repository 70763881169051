import { Alert } from "antd";

/** Props passed to the ErrorAlert component. */
interface ErrorAlertProps {
  /** List of errors to show in the alert. */
  errors: string[];
  /** Called when this component is closed. */
  onClose: () => void;
}

/**
 * Alert which shows the errors when selecting a file in PortfolioExport.
 */
export const PortfolioExportErrorAlert = ({
  errors,
  onClose
}: ErrorAlertProps) => {
  return <Alert closable onClose={onClose} message="Errors were detected" description={<div className="prose">
          <ul>
            {errors.map((error, index) => <li key={index}>{error}</li>)}
          </ul>
        </div>} type="error" style={{
    marginBottom: "1rem"
  }} data-sentry-element="Alert" data-sentry-component="PortfolioExportErrorAlert" data-sentry-source-file="PortfolioExportErrorAlert.tsx" />;
};