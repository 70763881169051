import routes from "./routes";
import type { HubTheme, HubThemePartial, PortfolioAssetLevelOutputType } from "./types";
import {
  getAestheticsConfig,
  getAllTools,
  getAllToolsAsObject,
  getAppName,
  getArchetypeByValue,
  getArchetypes,
  getCommsConfig,
  getConfig,
  getCoordinateBounds,
  getCurrencyCode,
  getDefaultInputValues,
  getDocsConfig,
  getKbConfig,
  getLocaleConfig,
  getPages,
  getRiskBandsMode,
  getSettings,
  getTheme,
  getTools,
  getToolsAsObject,
  getTrackingConfig,
} from "./utils";

export type { HubTheme, HubThemePartial, PortfolioAssetLevelOutputType };
// This may look weird, but it's to avoid a default export.
export {
  getAestheticsConfig,
  getAllTools,
  getAllToolsAsObject,
  getAppName,
  getArchetypeByValue,
  getArchetypes,
  getCommsConfig,
  getConfig,
  getCoordinateBounds,
  getCurrencyCode,
  getDefaultInputValues,
  getDocsConfig,
  getKbConfig,
  getLocaleConfig,
  getPages,
  getRiskBandsMode,
  getSettings,
  getTheme,
  getTools,
  getToolsAsObject,
  getTrackingConfig,
  routes,
};
