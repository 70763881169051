import { stringToArrayBuffer } from "@/utils/crypto";
import { KEY_PAIR_ALGORITHM } from "../systems/asymmetric/algorithms";
import { generateKeyPair } from "../systems/asymmetric/generation";
import { generateRandomSalt, generateSecretKey } from "../systems/symmetric/generation";
import type { EncryptedKeyPair, Salt } from "../types";

interface NewUserKeyPairs {
  encryptedKeyPair: EncryptedKeyPair;
  keyPair: CryptoKeyPair;
  salt: Salt;
}

/**
 * Generates a new key pair and encrypts it using the user's password.
 * @param password - User's password for logging in.
 * @returns Key pair (encrypted and plain), plus salt for decrypting key pair.
 */
export async function generateNewUserKeyPairs(password: string): Promise<NewUserKeyPairs> {
  const keyPair = await generateKeyPair();

  const salt = generateRandomSalt();
  const secretKey = await generateSecretKey(password, salt);

  const algorithm: AesGcmParams = {
    name: "AES-GCM",
    iv: salt,
  };

  const encryptedKeyPair = {
    privateKey: await crypto.subtle.wrapKey("pkcs8", keyPair.privateKey, secretKey, algorithm),
    publicKey: await crypto.subtle.wrapKey("spki", keyPair.publicKey, secretKey, algorithm),
  };

  return {
    encryptedKeyPair,
    keyPair,
    salt,
  };
}

/**
 * Decrypts a user's encrypted key pair.
 * @param encryptedKeyPair - Private/public key pair, encrypted with user's secret key.
 * @param password - Password user logs in with.
 * @param salt - Previously generated salt.
 * @returns Unencrypted key pair for user actions.
 */
export async function unwrapEncryptedKeyPair(
  encryptedKeyPair: EncryptedKeyPair,
  password: string,
  salt: Salt
): Promise<CryptoKeyPair> {
  const secretKey = await generateSecretKey(password, salt);

  const algorithm: AesGcmParams = {
    name: "AES-GCM",
    iv: salt,
  };

  return {
    privateKey: await crypto.subtle.unwrapKey(
      "pkcs8",
      encryptedKeyPair.privateKey,
      secretKey,
      algorithm,
      KEY_PAIR_ALGORITHM,
      true,
      ["decrypt"]
    ),
    publicKey: await crypto.subtle.unwrapKey(
      "spki",
      encryptedKeyPair.publicKey,
      secretKey,
      algorithm,
      KEY_PAIR_ALGORITHM,
      true,
      ["encrypt"]
    ),
  };
}

/**
 * Converts key pair to string and stores in local storage.
 * @param name - Local storage key.
 * @param key - Private/public key.
 * @returns void
 */
export async function addKeyInSessionStorage(name: string, key: CryptoKey) {
  crypto.subtle.exportKey("jwk", key).then((k) => {
    sessionStorage.setItem(name, JSON.stringify(k));
  });
}

/**
 * Get key stored as string in local storage and converts back to CryptoKey.
 * @param name - Local storage key.
 * @param keyUsage - ["encrypt"] for public key and ["decrypt"] for private key.
 * @returns void
 */
export async function getKeyFromSessionStorage(name: string, keyUsage: KeyUsage[]): Promise<CryptoKey> {
  const key = sessionStorage.getItem(name);
  if (!key) throw new Error("Couldn't find the key in local storage.");

  return await crypto.subtle.importKey("jwk", JSON.parse(key), KEY_PAIR_ALGORITHM, true, keyUsage);
}

/**
 * Encrypt string using the user/group key pair.
 * @param keyPair - User/Group Key Pair
 * @param data - data to be encrypted
 * @returns ArrayBuffer - encrypted data in binary format
 */
export async function encryptData(keyPair: CryptoKeyPair, data: string) {
  const enc = new TextEncoder();
  const encodedData = enc.encode(data);

  const encrypted = await crypto.subtle.encrypt(KEY_PAIR_ALGORITHM, keyPair.publicKey, encodedData);

  return encrypted;
}

/**
 * Decrypt string encrypted with user/group key pair.
 * @param keyPair - User/Group Key Pair
 * @param data - data to be decrypted
 * @returns string - decrypted data
 */
export async function decryptData(keyPair: CryptoKeyPair, data: string) {
  const encryptedArrayBuffer = stringToArrayBuffer(data);

  const decrypted = await crypto.subtle.decrypt(KEY_PAIR_ALGORITHM, keyPair.privateKey, encryptedArrayBuffer);

  const decoded = new TextDecoder().decode(decrypted);

  return decoded;
}

// ------------------------------

export async function importKey(key: string, keyUsage: KeyUsage[]) {
  return await crypto.subtle.importKey("jwk", JSON.parse(key), KEY_PAIR_ALGORITHM, true, keyUsage);
}

export async function exportKey(key: CryptoKey) {
  return await crypto.subtle.exportKey("jwk", key);
}
