import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./translations";
import type { TranslationOverride } from "./types";

i18n.use(initReactI18next).init({
  fallbackLng: "en-AU",
  lng: "en-AU",
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;

export const getTranslatedObject = (key: string) => {
  return i18n.getFixedT(i18n.language)(key, {
    returnObjects: true,
  });
};

export const getTranslatedText = (key: string): string => {
  return i18n.getFixedT(i18n.language)(key);
};

export type { TranslationOverride };
