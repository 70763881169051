import { services } from "@/store/services";
import dashboardsReducer from "@/store/slices/dashboards";
import libraryReducer from "@/store/slices/library";
import portfolioExportReducer from "@/store/slices/portfolioExport";
import userReducer from "@/store/slices/user";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { combineReducers } from "redux";

const reducer = combineReducers({
  dashboards: dashboardsReducer,
  library: libraryReducer,
  user: userReducer,
  portfolioExport: portfolioExportReducer,
  ...services.reducers,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(services.middlewares),
});

setupListeners(store.dispatch);

export type StoreState = ReturnType<typeof store.getState>;
