export const yearLimits = {
  min: 1990,
  max: 2100,
};

/**
 * Takes a string, parses it, and builds a year list from it.
 * @param input -
 * @returns
 */
export const buildYearListFromString = (input: string): number[] => {
  const output = input
    .split(",")
    .map((item) => item.trim())
    .reduce<number[]>(
      (list, value) => [...list, ...(value.indexOf("-") === -1 ? [+value] : convertYearRangeToList(value))],
      []
    );

  output.forEach((value, index) => {
    if (!value) {
      throw new Error("Invalid year detected. Ensure all inputs are numbers or valid year ranges.");
    }

    if (output.indexOf(value) !== index) {
      throw new Error(`Duplicate of year ${value} detected.`);
    }

    // Test inputs against limits.
    if (value < yearLimits.min || value > yearLimits.max) {
      throw new Error(`Years must be in range of ${yearLimits.min}-${yearLimits.max}.`);
    }
  });

  return output;
};

/**
 * Takes a year range string and converts it to a numerical array.
 * @param input -
 * @returns
 */
export const convertYearRangeToList = (input: string): number[] => {
  // Build step if it exists in input.
  let step = 1;
  if (input.indexOf("s") !== -1) {
    const segments = input.split("s");

    if (segments.length !== 2) {
      throw new Error("Range with step should be in format 0000-0000s00.");
    }

    input = segments[0];
    step = +segments[1];

    if (!step || step < 1 || step > 100) {
      throw new Error("Range step must be number between 1 and 100.");
    }
  }

  // This function depends on years being >=1000 and <=9999.
  // I think these are fine assumptions.
  if (input.length !== 9 && input[4] !== "-" && +input.replace("-", "")) {
    throw new Error("A year range must contain exactly two years separated by a hyphen.");
  }

  const [start, end] = input.split("-").map((year) => +year);

  // Test inputs against each other.
  if (start >= end) {
    throw new Error(`Start year must be smaller than end year in ${input}.`);
  }

  // Build year ranges.
  return Array(end - start + 1)
    .fill(0)
    .map((_, index) => start + index)
    .filter((_, index) => index % step === 0);
};

/**
 * Converts a list of years into a human readable format for confirmation.
 * @param yearList -
 * @returns
 */
export const humaniseYearList = (yearList: number[]): string => {
  // Handle a "no input" situation.
  if (yearList.length === 0) {
    return "";
  }

  // Handle a "single year" situation.
  if (yearList.length === 1) {
    return `Just ${yearList[0]}`;
  }

  // Handle a "multiple years" situation.
  let sequenceStart = yearList[0];
  let sequenceEnd = yearList[0];

  return yearList
    .sort()
    .slice(1) // Remove first year as it's already processed.
    .reduce<string[]>((list, year, index, arr) => {
      const isLastInList = index === arr.length - 1;
      const sequenceDiff = year - sequenceEnd;

      if (sequenceDiff === 0) {
        throw new Error("Double year value detected, which isn't supported.");
      }

      // Detect next step in a sequence.
      if (sequenceDiff === 1) {
        sequenceEnd = year;

        if (isLastInList) {
          return [...list, humaniseYearListChunk(sequenceStart, sequenceEnd)];
        }

        return list;
      }

      // At this point, we know that the current year is at least 2 away from last.

      // Previous sequence can be pushed on the list.
      const segments: string[] = [humaniseYearListChunk(sequenceStart, sequenceEnd)];

      // Update for next loop.
      sequenceStart = year;
      sequenceEnd = year;

      // If the final loop, at the segment on anyway.
      if (isLastInList) {
        segments.push(humaniseYearListChunk(sequenceStart, sequenceEnd));
      }

      return [...list, ...segments];
    }, [])
    .reduce<string>((text, segment, index, arr) => {
      if (index === 0) {
        segment = segment.slice(0, 1).toUpperCase() + segment.slice(1);
      }

      if (index === arr.length - 1) {
        segment = segment + ".";

        if (index !== 0) {
          segment = "and " + segment;
        }
      } else {
        segment = segment + ", ";
      }

      return text + segment;
    }, "");
};

const humaniseYearListChunk = (start: number, end: number): string => {
  return start === end ? `${start}` : `all years between ${start} and ${end}`;
};
