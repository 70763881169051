import { KEY_PAIR_ALGORITHM } from "./algorithms";

/**
 * Generates a key pair for asymmetric encryption.
 * @returns Newly generated private and public keys.
 */
export const generateKeyPair = async (): Promise<CryptoKeyPair> => {
  const keyUsages: readonly KeyUsage[] = ["encrypt", "decrypt"] as const;
  return await crypto.subtle.generateKey(KEY_PAIR_ALGORITHM, true, keyUsages);
};
