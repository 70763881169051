// import { defaultInputValues } from "../input_values";
import { ArchetypeConfig } from "../../schema";
import { defaultInputValues } from "../input_values";

const { aud } = defaultInputValues;

/**
 * Simple Residential archetypes.
 */
export const defaultSimpleResidentialArchetypes: ArchetypeConfig[] = [
  {
    id: "freestanding-house",
    name: "Freestanding House",
    value: "FreestandingHouse",
    category: "residential",
    description: "A regular single stand alone house.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.residential,
  },
  // {
  //   id: "simple-house",
  //   name: "Simple House",
  //   value: "SimpleHouse",
  //   category: "residential",
  //   description: "A regular single stand alone house.",
  //   defaultHeightAboveGround: 0.2,
  //   defaultInputValues: aud.residential,
  // },
  {
    id: "high-rise-flat",
    name: "High Rise Flat",
    value: "HighRiseFlat",
    category: "residential",
    description: "Multi-storey apartment living dwelling, characteristics of a 20+ storeys building.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.residential,
  },
  {
    id: "low-rise-flat",
    name: "Low Rise Flat",
    value: "LowRiseFlat",
    category: "residential",
    description: "Multi-storey apartment living dwelling, characteristics of less than 5 storeys.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.residential,
  },
];

/**
 * Simple Commercial archetypes.
 */
export const defaultSimpleCommercialArchetypes: ArchetypeConfig[] = [
  {
    id: "simple-office",
    name: "Simple Office",
    value: "SimpleOffice",
    category: "commercial",
    description: "Buildings range from 2 storeys to 9 storeys high - with mechanical lift.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "low-rise-structure",
    name: "Low Rise Structure",
    value: "LowRiseStructure",
    category: "commercial",
    description: "Multi-storey office or commercial building, less than 5 storeys. Shared spaces and equipment.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "high-rise-structure",
    name: "High Rise Structure",
    value: "HighRiseStructure",
    category: "commercial",
    description: "Multi-storey building of more than 5 storeys. Contains an array of complex elements.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "supermarket",
    name: "Supermarket",
    value: "Supermarket",
    category: "commercial",
    description: "A type of retail construction that stores food and non-food product ranges.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
];

/**
 * Simple Industrial archetypes.
 */
export const defaultSimpleIndustrialArchetypes: ArchetypeConfig[] = [
  {
    id: "control-room",
    name: "Control Room",
    value: "ControlRoom",
    category: "industrial",
    description: "A warehouse, factory, refinery, or general control room used alongside an industrial operation.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "substation",
    name: "Substation",
    value: "Substation",
    category: "industrial",
    description: "Dedicated facility to step-up or step-down the voltage within an electrical power grid.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "wind-turbine",
    name: "Wind Turbine",
    value: "WindTurbine",
    category: "industrial",
    description: "A machine that converts wind’s kinetic energy into electricity for generating renewable energy.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "park-complex",
    name: "Park Complex",
    value: "ParkComplex",
    category: "industrial",
    description: "Open green space that has complex elements such as irrigation or digital connectivity.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "council-building",
    name: "Council Building",
    value: "CouncilBuilding",
    category: "industrial",
    description: "Multi-purpose use building with mechanical equipment like HVAC and general purpose electronic.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "land",
    name: "Land",
    value: "Land",
    category: "industrial",
    description: "Plot of land with exposures to all hazards.",
    defaultHeightAboveGround: 0,
    defaultInputValues: aud.commercial,
  },
  {
    id: "generic-industrial",
    name: "Generic Industrial",
    value: "GenericIndustrial",
    category: "industrial",
    description: "A proxy for an unknown archetype. Likely a operational building or warehouse.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "track-main",
    name: "Track Main",
    value: "TrackMain",
    category: "industrial",
    description: "Track as a whole structure including rails, sleepers, fasteners, and ballast.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "gas-distribution",
    name: "Gas Distribution",
    value: "GasDistribution",
    category: "industrial",
    description: "A facility that helps the movement of gas from one location to another in a pipeline system.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "overhead-power-pole",
    name: "Overhead Power Pole",
    value: "Overhead",
    category: "industrial",
    description: "A power pole is a single pole carrying low voltage, medium voltage, or high voltage lines.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "berth",
    name: "Berth",
    value: "Berth",
    category: "industrial",
    description: "A designated location in a port or harbour used for mooring vessel.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
  {
    id: "shiploader",
    name: "Shiploader",
    value: "Shiploader",
    category: "industrial",
    description: "Sits on the Berth/Wharf to load bulk materials/cargo on or off ships for transportation.",
    defaultHeightAboveGround: 0.2,
    defaultInputValues: aud.commercial,
  },
];

/**
 * Combined Simple Structural archetypes.
 */
export const defaultSimpleStructuralArchetypes = [
  ...defaultSimpleResidentialArchetypes,
  ...defaultSimpleCommercialArchetypes,
  ...defaultSimpleIndustrialArchetypes,
];
