import { facadesApi } from "@/store/services/facades";
import { FacadesBatchPostResponse } from "@/store/services/facades/types";
import { HaloRequest, HaloResponse, RawHaloResponse } from "@/store/services/facades/types/halo";

const PROJECT_HALO_HEADERS = {
  Authorization: `Basic ${process.env.NEXT_PUBLIC_FACADES_ABCDE_API_KEY}`,
};

export const haloFacadesApi = facadesApi.injectEndpoints({
  endpoints: (build) => ({
    getHaloRiskBands: build.query<HaloResponse, HaloRequest>({
      query: (body) => {
        return {
          method: "POST",
          url: "project_halo",
          headers: PROJECT_HALO_HEADERS,
          body,
        };
      },
      transformErrorResponse: (error) => {
        const errorData = error.data as {
          title: string;
          status: number;
          detail: string;
          type: string;
        };

        const detail =
          errorData.detail === "Try adding more details to the address and try again"
            ? "Specify addresses in accordance with the format used by Hongkong Post. Additional address elements such as business names and unit, suite or floor numbers should be avoided."
            : errorData.detail;

        return {
          message:
            errorData.title && detail
              ? `${errorData.title}. ${detail}.`
              : "Something unexpected happened. Please try again.",
          status: errorData.status,
        };
      },
      transformResponse: (response: RawHaloResponse): HaloResponse => {
        return {
          mode: "halo",
          ...response,
        };
      },
    }),

    postHaloBatch: build.query<FacadesBatchPostResponse, string[]>({
      query: (body) => {
        return {
          method: "POST",
          url: "project_halo/batch",
          headers: {
            ...PROJECT_HALO_HEADERS,
            "Content-Type": "application/x-ndjson",
          },
          body: body.join("\n"),
        };
      },
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
});
