import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Timeline, TimelineProps } from "antd";
export interface SteppedTimelineProps extends TimelineProps {
  current: number;
  steps: string[];
}
export const SteppedTimeline = ({
  current,
  steps,
  ...props
}: SteppedTimelineProps) => {
  return <Timeline className="w-full text-left" mode="alternate" items={steps.slice(0, current).map((children, key) => {
    return {
      key,
      children
    };
  })} pending={current < steps.length && steps[current]} pendingDot={<SteppedTimelineIndicator />} {...props} data-sentry-element="Timeline" data-sentry-component="SteppedTimeline" data-sentry-source-file="SteppedTimeline.tsx" />;
};
const SteppedTimelineIndicator = () => {
  return <Spin indicator={<LoadingOutlined style={{
    fontSize: 12
  }} />} data-sentry-element="Spin" data-sentry-component="SteppedTimelineIndicator" data-sentry-source-file="SteppedTimeline.tsx" />;
};