import { UserNotification } from "@/types/user";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  notifications: UserNotification[];
}

const initialState: UserState = {
  notifications: [
    {
      id: "4321-1234",
      title: "Welcome to the Hub",
      body: "This program is in the very early stages of development. As such, please expect to encounter bugs and non-functional interfaces. Thank you!",
      hasSeen: false,
    },
  ],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Omit<UserNotification, "hasSeen">>) => {
      state.notifications = [
        {
          ...action.payload,
          hasSeen: false,
        },
        ...state.notifications,
      ];
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(({ id }) => {
        return id !== action.payload;
      });
    },
    viewAllNotifications: (state) => {
      state.notifications = state.notifications.map((notification) => {
        return {
          ...notification,
          hasSeen: true,
        };
      });
    },
    viewNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.map((notification) => {
        if (notification.id === action.payload) {
          return {
            ...notification,
            hasSeen: true,
          };
        }

        return notification;
      });
    },
  },
});

export const { addNotification, removeNotification, viewAllNotifications, viewNotification } = userSlice.actions;

export default userSlice.reducer;
