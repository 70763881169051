import { Settings } from "../types/settings";
import { scenarioAllYears, scenarioDefault } from "./settings/scenario";
import { structuralSettingsElements, structuralSettingsFull } from "./settings/structural_settings";

interface TemplateGroup {
  id: string;
  title: string;
}

interface Template {
  id: string;
  title: string;
  groupId: string;
  settings: Settings;
}

export const templateGroups: TemplateGroup[] = [
  {
    id: "structural",
    title: "Structural",
  },
];

export const templates: Template[] = [
  {
    id: "structuralElementsAllRcps",
    title: "Elements - All RCPs, 5 Year Step",
    groupId: "structural",
    settings: {
      mode: "structural",
      steps: [{ type: "full_chain" }, { type: "export_all_rcps" }],
      files: {
        "db/Scenario.yaml": scenarioDefault,
        "db/structural_settings.yaml": structuralSettingsElements,
      },
    },
  },
  {
    id: "structuralFullAllRcps",
    title: "Full - All RCPs, 5 Year Step",
    groupId: "structural",
    settings: {
      mode: "structural",
      steps: [{ type: "full_chain" }, { type: "export_all_rcps" }],
      files: {
        "db/Scenario.yaml": scenarioDefault,
        "db/structural_settings.yaml": structuralSettingsFull,
      },
    },
  },
  {
    id: "structuralFullAllRcpsYearly",
    title: "Full - All RCPs, All Years",
    groupId: "structural",
    settings: {
      mode: "structural",
      steps: [{ type: "full_chain" }, { type: "export_all_rcps" }],
      files: {
        "db/Scenario.yaml": scenarioAllYears,
        "db/structural_settings.yaml": structuralSettingsFull,
      },
    },
  },
];
