import { ExportOutlined } from "@ant-design/icons";
import { Button, Modal as AntdModal, ModalProps as AntdModalProps } from "antd";
import { getExternalUrlForEmbed } from "../functions";
import type { Props } from "../types";
import { Embed } from "./index";
export interface ModalProps extends AntdModalProps {
  data: Props;
  embedHeight?: string;
  showOpenInNewTab?: boolean;
}
export const Modal = ({
  data,
  embedHeight = "550px",
  showOpenInNewTab = false,
  ...props
}: ModalProps) => {
  const externalUrl = getExternalUrlForEmbed(data);
  return <AntdModal footer={!!externalUrl && <a href={externalUrl} target="_blank" rel="noreferrer">
            {showOpenInNewTab && <Button>
                Open in new tab <ExportOutlined />
              </Button>}
          </a>} width="900px" {...props} data-sentry-element="AntdModal" data-sentry-component="Modal" data-sentry-source-file="Modal.tsx">
      <Embed style={{
      height: embedHeight
    }} data={data} data-sentry-element="Embed" data-sentry-source-file="Modal.tsx" />
    </AntdModal>;
};