import { JobSchema, PollResult } from "../types/jobs";
import { JobMonitor } from "./JobMonitor";

/**
 * Keeps track of a list of jobs.
 */
export class JobMonitorAggregator {
  protected monitors: JobMonitor[] = [];

  /**
   * Interval between automatic polls.
   */
  public pollInterval = 5000;

  /**
   * Keep track of internal timeout.
   * NOTE: We use a timeout instead of an interval so that if the interval
   * changes, we can adapt at next poll.
   */
  protected timeoutId?: NodeJS.Timeout;

  constructor(protected onPoll: (schemas: Required<JobSchema>[]) => void) {}

  /**
   * Adds a new job monitor to the internal array.
   * @param schema - Description of the job to be added.
   */
  public addBySchema = (schema: JobSchema) => {
    const monitor = new JobMonitor(schema);
    this.monitors = [monitor, ...this.monitors];
  };

  /**
   * Returns the list of schemas for every job monitor.
   * @returns All schemas for all job monitors.
   */
  public getAllSchemas = (): Required<JobSchema>[] => {
    return this.monitors.map((monitor) => monitor.schema);
  };

  public setAll = (schemas: Required<JobSchema>[]) => {
    this.monitors = schemas.map((schema) => new JobMonitor(schema));
  };

  /**
   * Perform a poll on all job monitors.
   */
  public pollAll = async (): Promise<PollResult[]> => {
    return this.monitors.length === 0
      ? new Promise(() => [])
      : Promise.all(this.monitors.map((monitor) => monitor.poll()));
  };

  /**
   * Begins automatic polling.
   */
  public startPolling = () => {
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
    }

    this.pollAll().then(() => this.onPoll(this.getAllSchemas()));
    this.timeoutId = setTimeout(() => this.startPolling(), this.pollInterval);
  };

  /**
   * Ends automatic polling.
   * @returns Whether or not polling was stopped.
   */
  public stopPolling = (): boolean => {
    if (this.timeoutId === undefined) {
      return false;
    }

    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
    return true;
  };
}
