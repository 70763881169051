import { WarningTwoTone } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import styles from "./AcceptTerms.module.scss";
const LOCAL_STORAGE_KEY = "acceptedTerms";
export const AcceptTerms = () => {
  const [visible, setVisible] = useState(!!(localStorage && localStorage.getItem(LOCAL_STORAGE_KEY) !== "true"));
  const accept = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "true");
    setVisible(false);
  };
  return <Modal closable={false} open={visible} title={<div className={styles.titleContainer}>
          <h2 className={styles.title}>Beta System</h2>
          <WarningTwoTone className={styles.titleIcon} twoToneColor={"#f59e0b"} />
        </div>} footer={<Button onClick={accept} type="primary" block size="large">
          I Understand
        </Button>} data-sentry-element="Modal" data-sentry-component="AcceptTerms" data-sentry-source-file="AcceptTerms.tsx">
      <div className={styles.blurbContainer}>
        <p className={styles.blurb}>
          <strong>Please be advised that the Physical Risk Assessment Platform is currently in beta.</strong>
        </p>
        <p className={styles.blurb}>
          While we strive to provide you with a valuable experience, there may be occasional issues or changes as we
          continue to refine and improve the system. Your feedback is highly appreciated during this phase, and we
          encourage you to report any anomalies or suggestions to help us enhance the platform&apos;s performance and
          functionality.
        </p>
      </div>
    </Modal>;
};