import { Avatar } from "@/components/buttons/Avatar";
import { getSettings } from "@/features/branding";
import supabase from "@/features/supabase";
import { User } from "@/store/services/supabase";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
type AccountDropdownButtonProps = User;
export const AccountDropdownButton = (user?: AccountDropdownButtonProps) => {
  const {
    t
  } = useTranslation("authentication");
  const router = useRouter();
  if (!user) {
    return <div>Not logged in</div>;
  }
  const buildSettingsItems = (): ItemType[] => {
    // Do not show settings button if restricted.
    // TODO: Remove this when settings can be enabled for everyone (we have email invites, etc.).
    if (!getSettings().enabled) {
      return [];
    }
    return [{
      key: "settings",
      label: <div style={{
        display: "flex",
        alignItems: "center",
        gap: "8px"
      }}>
            <SettingOutlined />
            <span>Settings</span>
          </div>,
      onClick: () => router.push("/settings/account/preferences")
    }, {
      type: "divider"
    }];
  };
  const items: ItemType[] = [{
    key: "email",
    label: <div>
          <div>{user.email}</div>
          <div className="my-0.5 text-sm leading-normal text-gray-500">
            <div>{user.group_name}</div>
            <div>{user.org_name}</div>
          </div>
        </div>,
    style: {
      pointerEvents: "none"
    }
  }, {
    type: "divider"
  }, ...buildSettingsItems(), {
    key: "logout",
    onClick: async () => {
      await supabase.auth.signOut();
    },
    label: t("logOut")
  }];
  return <>
      <Dropdown menu={{
      items
    }} trigger={["click"]} className="cursor-pointer" data-sentry-element="Dropdown" data-sentry-source-file="AccountDropdownButton.tsx">
        <Button icon={<Avatar name={user.email || ""} />} type="text" data-sentry-element="Button" data-sentry-source-file="AccountDropdownButton.tsx"></Button>
      </Dropdown>
    </>;
};