import { is5xxHttpStatus } from "@/utils/http";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_FACADES_URL = "https://api.climaterisk.net";
export const facadesUrl = `${process.env.NEXT_PUBLIC_FACADES_URL || DEFAULT_FACADES_URL}/v1/`;

const staggeredBaseQuery = retry(
  fetchBaseQuery({
    baseUrl: facadesUrl,
    headers: {
      "Content-Type": "application/json",
    },
  }),
  {
    retryCondition: (error, _, { attempt, extraOptions }) => {
      if (attempt >= (extraOptions?.maxRetries ?? DEFAULT_MAX_RETRIES)) return false;

      if (typeof error.status !== "number") return true;

      return is5xxHttpStatus(error.status);
    },
  }
);

export const facadesApi = createApi({
  reducerPath: "facades",
  baseQuery: staggeredBaseQuery,
  endpoints: () => ({}),
});
