import { ContextualInfoButton } from "@/components/buttons/ContextualInfoButton";
import { AddressLookup } from "@/components/inputs/AddressLookup";
import { RiskBandsAdvancedSettings, RiskBandsAdvancedSettingsRefs } from "@/components/layouts/RiskBandsAdvancedSettings";
import { getArchetypes, getRiskBandsMode } from "@/features/branding";
import { isPointWithinSystemBounds } from "@/features/geographic_data";
import { HubMapLocationPicker } from "@/features/maps";
import { invalidLatLng, LatLng, latLngSchema } from "@/lib/crg_apis/types/latlng";
import { tourSingleResponseAbc, tourSingleResponseHalo } from "@/tools/risk-bands/tutorials/data/single";
import { SingleAssetScreenTutorial } from "@/tools/risk-bands/tutorials/single";
import { RiskBandsAdvancedSettingsValues, RiskBandsArchetype } from "@/tools/risk-bands/types";
import { Tool, ToolProps } from "@/types/tools";
import { limitLatLngTo6dp } from "@/utils/geocoding";
import { CompressOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Input, Row, Space, Typography } from "antd";
import { LatLngLiteral } from "leaflet";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleResultModal, SingleResultModalRefs } from "./components/SingleResultModal";
const {
  TextArea
} = Input;
const {
  Text,
  Title
} = Typography;

/**
 * Retrieve the risk bands for a single asset.
 */
const RiskBandsSingleAssetTool = (props: ToolProps) => {
  const {
    t
  } = useTranslation();

  // Dummy data used for displaying the tour.
  const tourResponse = {
    abc: tourSingleResponseAbc,
    halo: tourSingleResponseHalo,
    simple: tourSingleResponseHalo
  }[getRiskBandsMode()];
  const addressRef = useRef(null);
  const checkRef = useRef(null);
  const mapRef = useRef(null);
  const [settingsRefs, setSettingsRefs] = useState<RiskBandsAdvancedSettingsRefs | undefined>(undefined);
  const [modalRefs, setModalRefs] = useState<SingleResultModalRefs | undefined>(undefined);
  const archetypes = getArchetypes();
  const [address, setAddress] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [position, setPosition] = useState(invalidLatLng);
  const [isWithinBounds, setIsWithinBounds] = useState(true);
  const defaultArchetype = archetypes[0];
  const defaultBuildYear = 1980;
  const [advancedSettings, setAdvancedSettings] = useState<RiskBandsAdvancedSettingsValues>({
    archetype: defaultArchetype.value as RiskBandsArchetype,
    buildYear: defaultBuildYear,
    heightAboveGround: {
      source: "archetype",
      amount: defaultArchetype.defaultHeightAboveGround || 0
    }
  });
  const handleChange = (newAddress: string, newPosition: LatLng) => {
    setAddress(newAddress);
    setPosition(newPosition);
    setIsWithinBounds(isPointWithinSystemBounds(newPosition));
  };
  const handleReset = () => {
    setIsModalOpen(false);
    setAddress("");
    setPosition(invalidLatLng);
    setIsWithinBounds(true);
    setAdvancedSettings({
      archetype: defaultArchetype.value as RiskBandsArchetype,
      buildYear: defaultBuildYear,
      heightAboveGround: {
        source: "archetype",
        amount: defaultArchetype.defaultHeightAboveGround || 0
      }
    });
  };
  const showPointOutsideBoundsWarning = !isWithinBounds && position !== invalidLatLng;
  return <>
      <Row gutter={[16, 0]} className="mb-8 min-h-[400px] w-full" data-sentry-element="Row" data-sentry-source-file="single.tsx">
        <Col flex={1} ref={mapRef} data-sentry-element="Col" data-sentry-source-file="single.tsx">
          <Title level={5} className="mt-0 pt-0" data-sentry-element="Title" data-sentry-source-file="single.tsx">
            Select A Point
          </Title>
          <HubMapLocationPicker className="h-[70vh] overflow-hidden rounded-md border" onChange={(position: LatLngLiteral) => {
          const position6dp = limitLatLngTo6dp(position);
          handleChange(`(${position6dp.lat}, ${position6dp.lng})`, position);
        }} position={latLngSchema.safeParse(position).success ? position : undefined} data-sentry-element="HubMapLocationPicker" data-sentry-source-file="single.tsx" />
        </Col>
        <Col flex={0} className="w-full max-w-[300px]" data-sentry-element="Col" data-sentry-source-file="single.tsx">
          <Row gutter={[0, 12]} data-sentry-element="Row" data-sentry-source-file="single.tsx">
            <Col span={24} ref={addressRef} data-sentry-element="Col" data-sentry-source-file="single.tsx">
              <Title level={5} className="mt-0 pt-0" data-sentry-element="Title" data-sentry-source-file="single.tsx">
                <Space data-sentry-element="Space" data-sentry-source-file="single.tsx">
                  Search For A Location <ContextualInfoButton category="guide" id="enteringLocationsAndAddresses" data-sentry-element="ContextualInfoButton" data-sentry-source-file="single.tsx" />
                </Space>
              </Title>
              <AddressLookup address={address} onChange={handleChange} onClear={() => handleChange("", invalidLatLng)} placeholder="" data-sentry-element="AddressLookup" data-sentry-source-file="single.tsx">
                <TextArea rows={2} placeholder="Enter address" data-sentry-element="TextArea" data-sentry-source-file="single.tsx" />
              </AddressLookup>
            </Col>
            <Col span={24} data-sentry-element="Col" data-sentry-source-file="single.tsx">
              <Text type="secondary" className="italic" data-sentry-element="Text" data-sentry-source-file="single.tsx">
                {t("widgets:map.hints.click")}
              </Text>
            </Col>
            {showPointOutsideBoundsWarning && <Col span={24}>
                <Alert showIcon type="error" message="Selected point is outside of system bounds and therefore cannot be processed." />
              </Col>}
            <Divider className="m-0" data-sentry-element="Divider" data-sentry-source-file="single.tsx" />
            <Col span={24} className="flex items-center justify-between p-0" data-sentry-element="Col" data-sentry-source-file="single.tsx">
              <Title level={5} className="mt-0 pt-0" data-sentry-element="Title" data-sentry-source-file="single.tsx">
                Settings
              </Title>
              <Button onClick={handleReset} type="text" className="opacity-70" data-sentry-element="Button" data-sentry-source-file="single.tsx">
                Reset
              </Button>
            </Col>
            <Col span={24} data-sentry-element="Col" data-sentry-source-file="single.tsx">
              <RiskBandsAdvancedSettings mode="single" values={advancedSettings} setRefs={setSettingsRefs} setValues={setAdvancedSettings} data-sentry-element="RiskBandsAdvancedSettings" data-sentry-source-file="single.tsx" />
            </Col>
            <Divider className="m-0" data-sentry-element="Divider" data-sentry-source-file="single.tsx" />
            <Col span={24} ref={checkRef} data-sentry-element="Col" data-sentry-source-file="single.tsx">
              <Button block disabled={!address || !isWithinBounds} onClick={() => setIsModalOpen(true)} type="primary" data-sentry-element="Button" data-sentry-source-file="single.tsx">
                {t("actions:check")}
              </Button>
            </Col>
            <Col span={24} data-sentry-element="Col" data-sentry-source-file="single.tsx" />
            {isModalOpen && <SingleResultModal address={address} advancedSettings={advancedSettings} customResponse={props.isTutorialOpen ? tourResponse : undefined} onClose={() => setIsModalOpen(false)} onReset={handleReset} position={position} setRefs={setModalRefs} />}
          </Row>
        </Col>
      </Row>

      <SingleAssetScreenTutorial open={props.isTutorialOpen} onClose={() => {
      props.setIsTutorialOpen(false);
      setIsModalOpen(false);
      setAddress("");
      setPosition(invalidLatLng);
    }} onChange={step => {
      // Open modal if in second half of tutorial.
      setIsModalOpen(step > 5);
    }} setState={data => {
      setAddress(data.address);
      setPosition(data.position);
    }} refs={{
      address: addressRef,
      advancedSettings: settingsRefs,
      check: checkRef,
      map: mapRef,
      modal: modalRefs
    }} data-sentry-element="SingleAssetScreenTutorial" data-sentry-source-file="single.tsx" />
    </>;
};
export default {
  id: "single",
  category: "riskBands",
  keyPrefix: "riskBands.single",
  icon: <CompressOutlined />,
  render: props => <RiskBandsSingleAssetTool {...props} />,
  maxWidth: "1400px",
  hasTutorial: true,
  showUsage: true
} as Tool;