import type { Props } from "../types";

/**
 * This is for "open in new tab" of embeds.
 * NOTE: This should generally be different to the `src` tag in the embeds.
 */
export const getExternalUrlForEmbed = (props: Props): string | undefined => {
  switch (props.type) {
    case "scribe":
      return `https://scribehow.com/shared/${props.slug}`;

    case "youtube":
      return `https://www.youtube.com/watch?v=${props.id}`;

    default:
      return;
  }
};
