import { AbcdeRiskValue, ensureGradedRiskValue } from "@/features/risk_bands";
import { RiskBandsRiskValue } from "@/store/services/facades/types";
import { Col, Row } from "antd";
import styles from "./RiskBand.module.scss";
import { SingleLetterRiskBand } from "./SingleLetterRiskBand";
import { WordyRiskBand } from "./WordyRiskBand";
const colours: Record<AbcdeRiskValue, string> = {
  A: "#56BD5C",
  B: "#F4C747",
  C: "#D6305A",
  D: "#000",
  E: "#df0000"
};

/**
 * External-facing components.
 */

export interface RiskBandProps {
  rating?: RiskBandsRiskValue | null;
  size?: number;
  showAsWordy?: boolean;
}
export const RiskBand = ({
  rating,
  size = 120,
  showAsWordy = false
}: RiskBandProps) => {
  const grade = rating ? ensureGradedRiskValue(rating) : "?";
  const colour = grade === "?" ? "#777" : colours[grade];
  return showAsWordy ? <WordyRiskBand colour={colour} rating={grade} size={size} /> : <SingleLetterRiskBand colour={colour} rating={grade} size={size} />;
};
export interface RiskBandWithLabelProps extends RiskBandProps {
  label: string;
  labelFontSize?: string;
}
export const RiskBandWithLabel = ({
  labelFontSize,
  label,
  ...props
}: RiskBandWithLabelProps) => {
  return <Row justify="center" gutter={[0, 12]} data-sentry-element="Row" data-sentry-component="RiskBandWithLabel" data-sentry-source-file="RiskBand.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="RiskBand.tsx">
        <RiskBand {...props} data-sentry-element="RiskBand" data-sentry-source-file="RiskBand.tsx" />
      </Col>
      <Col className={styles.label} span={24} style={{
      fontSize: labelFontSize
    }} data-sentry-element="Col" data-sentry-source-file="RiskBand.tsx">
        {label}
      </Col>
    </Row>;
};