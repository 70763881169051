import { Features } from "../../types/features";

const features: Features = {
  assetPreview: {
    title: "Asset Preview",
  },
  changeLog: {
    title: "Changelog",

    categories: {
      added: "Added",
      changed: "Changed",
      fixed: "Fixed",
      removed: "Removed",
      security: "Security",
      deprecated: "Deprecated",
    },
  },
  inputModules: {
    anaBatch: {
      title: "BLAZE",
      subtitle: "Process a list of assets through BLAZE.",
    },
    singleRiskBands: {
      title: "Single Risk Bands",
      subtitle: "Add a single asset's risk bands to the library.",
    },
    singleAsset: {
      title: "Single Asset",
      subtitle: "Add a single full asset to the library.",
    },
    multipleAssets: {
      title: "Multiple Assets",
      subtitle: "Add multiple assets to the library.",
    },
  },
};

export default features;
