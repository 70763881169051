import { z } from "zod";

// Structure of posts should be:
// {
//   glossary: {
//     riskBands: "risk-bands",
//   }
// }
// Where "risk-bands" is the end of the slug for the blog post.

export const postCategorySchema = z.record(z.string(), z.union([z.string(), z.function().returns(z.string())]));

export const postIdsSchema = z.object({
  glossary: postCategorySchema,
  guide: postCategorySchema,
  method: postCategorySchema,
  test: postCategorySchema,
});

export const postCategoryIdSchema = postIdsSchema.keyof();

export type PostCategoryId = z.infer<typeof postCategoryIdSchema>;
export type PostCategory = z.infer<typeof postCategorySchema>;
export type PostIds = z.infer<typeof postIdsSchema>;
