import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PortfolioExportState {
  newRun: {
    totalInSupabase: number;
    totalInBatch: {
      [k in string]: number;
    };
  };
  assetLevelDownload: {
    [k in string]: number;
  };
}

const initialState: PortfolioExportState = {
  newRun: {
    totalInSupabase: 0,
    totalInBatch: {},
  },
  assetLevelDownload: {},
};

export const portfolioExportSlice = createSlice({
  name: "portfolioExport",
  initialState,
  reducers: {
    incrementNewRunTotalInSupabaseBy: (state, action: PayloadAction<number>) => {
      state.newRun.totalInSupabase = state.newRun.totalInSupabase + action.payload;
    },
    setNewRunTotalInSupabaseTo: (state, action: PayloadAction<number>) => {
      state.newRun.totalInSupabase = action.payload;
    },
    setNewRunTotalInBatchTo: (state, action: PayloadAction<{ id: string; count: number }>) => {
      state.newRun.totalInBatch = {
        ...state.newRun.totalInBatch,
        [action.payload.id]: action.payload.count,
      };
    },
  },
});

export const portfolioExportActions = portfolioExportSlice.actions;

export default portfolioExportSlice.reducer;
