import { HubGeocoderResult } from "../types";
import { HongKongGovSuggestedAddress } from "./types";

/**
 * Converts a HK gov API suggested address into a result.
 * @param input - HK gov API suggested address
 * @returns HubGeocoderResult
 */
export const buildResultFromHongKongAddress = (
  input: HongKongGovSuggestedAddress,
  original: string
): HubGeocoderResult => {
  const { Latitude, Longitude } = input.Address.PremisesAddress.GeospatialInformation;

  return {
    name: formatHongKongAddress(input),
    input: original,
    latLng: {
      lat: parseFloat(Latitude),
      lng: parseFloat(Longitude),
    },
    confidence: input.ValidationInformation.Score,
    source: "hkgov",
  };
};

/**
 * Formats a suggested address from the HK gov API.
 * TODO: We need to triple-check that this logic is sound and the result is correct.
 * @see https://www.grcdi.nl/gsb/hong%20kong.html
 * @param input - Response from HK gov address lookup.
 * @returns Formatted HK address.
 */
export const formatHongKongAddress = (input: HongKongGovSuggestedAddress): string => {
  const { BuildingName, EngBlock, EngDistrict, EngEstate, EngStreet, Region } =
    input.Address.PremisesAddress.EngPremisesAddress;

  let address = "";

  if (EngBlock) {
    const descriptor = EngBlock.BlockDescriptor;
    address += `${descriptor === "BLK" ? "BLOCK" : descriptor} ${EngBlock.BlockNo}, `;
  }

  if (BuildingName || EngEstate) {
    address += `${EngEstate?.EstateName || BuildingName}, `;
  }

  address += `${EngStreet.BuildingNoFrom} ${EngStreet.StreetName}`;

  return `${address}, ${EngStreet.LocationName || EngDistrict.DcDistrict}, ${Region}`;
};
