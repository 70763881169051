import { getConfig } from "@/features/branding";
import { HubGeocoder } from "../types";
import { buildResultFromGoogleGeocoderResponse } from "./utils";

let _autocompleteService: google.maps.places.AutocompleteService;
let _geocoder: google.maps.Geocoder;

// We do this here so as to give Google's API a chance to load.
const getAutocompleteService = (): google.maps.places.AutocompleteService => {
  if (!_autocompleteService) {
    _autocompleteService = new google.maps.places.AutocompleteService();
  }
  return _autocompleteService;
};

const getGeocoder = (): google.maps.Geocoder => {
  if (!_geocoder) {
    _geocoder = new google.maps.Geocoder();
  }
  return _geocoder;
};

export const googleGeocoder: HubGeocoder = {
  name: "Google",
  reference: "",
  stringToResult: async (address) => {
    const { geocode } = getGeocoder();
    try {
      const response = await geocode({
        address,
        componentRestrictions: getConfig().geocoding.locationRestrictions,
      });
      const result = buildResultFromGoogleGeocoderResponse(response);

      if (!result) {
        return undefined;
      }

      return {
        input: address,
        ...result,
      };
    } catch (err) {
      return undefined;
    }
  },
  autocompleter: {
    getSuggestions: async (input) => {
      const autocompleteService = getAutocompleteService();
      const response = await autocompleteService.getPlacePredictions({
        input,
        componentRestrictions: {
          country: getConfig().geocoding.locationRestrictions.country || null,
        },
      });
      return response.predictions.map(({ description, place_id }) => ({
        data: place_id,
        value: description,
      }));
    },
    suggestionToResult: async ({ data: placeId }) => {
      const { geocode } = getGeocoder();
      try {
        const response = await geocode({
          placeId,
          // TODO: Figure out why this won't work with placeId. Fortunately,
          // placeId is already so specific that I don't think we need this.
          // componentRestrictions: getConfig().geocoding.locationRestrictions,
        });
        const result = buildResultFromGoogleGeocoderResponse(response);

        if (!result) {
          return undefined;
        }

        return {
          input: placeId,
          ...result,
        };
      } catch (err) {
        return undefined;
      }
    },
  },
};
