import { acrobaseApi } from "@/store/services/acrobase";
import { facadesApi } from "@/store/services/facades";
import { internalApi } from "@/store/services/internal";
import { supabaseApi } from "@/store/services/supabase";
import type { Middleware } from "redux";

const apis = [acrobaseApi, facadesApi, internalApi, supabaseApi];

export const services = {
  middlewares: apis.map(({ middleware }) => middleware as Middleware),
  reducers: apis.reduce((reducers, { reducerPath, reducer }) => {
    return { ...reducers, [reducerPath]: reducer };
  }, {}),
};
